import { Action, createReducer, on } from '@ngrx/store';
import { UploadAttachmentsState } from './upload-attachments.types';
import { uploadAttachmentsAction, uploadAttachmentsSuccessAction, uploadAttachmentsFailureAction } from './upload-attachments.actions';

const initialState = {
  uploading: false,
  uploaded: false,
  data: null,
  error: null,
};

const uploadAttachmentsReducer = createReducer<UploadAttachmentsState>(
  initialState,
  on(
    uploadAttachmentsAction,
    state => ({
      ...state,
      uploading: true,
      uploaded: false,
    })
  ),
  on(
    uploadAttachmentsSuccessAction,
    (state, action) => ({
      ...state,
      uploading: false,
      uploaded: true,
      data: action.data,
      error: null,
    })
  ),
  on(
    uploadAttachmentsFailureAction,
    (state, action) => ({
      ...state,
      uploading: false,
      uploaded: false,
      error: action.error,
    })
  ),
);

export const reducer = (state: UploadAttachmentsState, action: Action) => uploadAttachmentsReducer(state, action);
