import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { getCompanySettingsAction } from '../../company-settings/get-company-settings/store/get-company-settings.actions';
import { selectGetCompanySettingsLoaded } from '../../company-settings/get-company-settings/store/get-company-settings.selectors';

@Injectable({ providedIn: 'root' })
export class CompanySettingsResolver {
  constructor(private store$: Store) {
  }

  resolve(): Observable<boolean> {
    this.store$.dispatch(getCompanySettingsAction());
    return this.store$.pipe(
      select(selectGetCompanySettingsLoaded),
      filter(loaded => loaded),
      take(1),
    );
  }
}
