import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UpdateTutorialProgressEffects } from './store/update-tutorial-progress.effects';
import { reducer } from './store/update-tutorial-progress.reducers';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([UpdateTutorialProgressEffects]),
    StoreModule.forFeature('updateTutorialProgress', reducer),
  ]
})
export class UpdateTutorialProgressModule {
}
