import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/update-local-settings.reducers';
import { UpdateLocalSettingsEffects } from './store/update-local-settings.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([UpdateLocalSettingsEffects]),
    StoreModule.forFeature('updateLocalSettings', reducer),
  ]
})
export class UpdateLocalSettingsModule { }
