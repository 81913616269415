import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { updateAccountAction, updateAccountFailureAction, updateAccountSuccessAction } from './update-account.actions';
import { updateAccountService } from '../update-account.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class UpdateAccountEffects {
  updateAccount$ = createEffect(() => this.actions$.pipe(
    ofType(updateAccountAction),
    switchMap(action =>
      this.updateAccountService.update(action.payload).pipe(
        map(response => updateAccountSuccessAction({ data: response })),
        catchError(error => of(updateAccountFailureAction({ error }))),
      )
    )
  ));

  updateAccountSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(updateAccountSuccessAction),
    tap(() => this.notifyService.success('Account updated successfully'))
  ), { dispatch: false });

  updateAccountFailure$ = createEffect(() => this.actions$.pipe(
    ofType(updateAccountFailureAction),
    tap(({ error }) => this.notifyService.error(error.message || 'Failed to update account'))
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private updateAccountService: updateAccountService,
    private notifyService: ToastrService,
  ) {
  }
}
