import { Injectable } from '@angular/core';
import { Observable, switchMap, takeWhile } from 'rxjs';
import { collection, Firestore, collectionData } from '@angular/fire/firestore';
import { filter } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { selectCurrentUserId } from '../../../current-user/store/current-user.selectors';
import { inputIsNotNullOrUndefined } from '../../../data-access/input-is-not-null-or-undefined';
import { UserService } from '../../../auth/services/user.service';

export interface ActionItem {
  id: string,
  name: string,
}

@Injectable({
  providedIn: 'root',
})
export class GetActionItemsService {

  constructor(private firestore: Firestore, private store$: Store, private userService: UserService) {
  }

  get(): Observable<ActionItem[]> {
    return <Observable<ActionItem[]>>this.store$.pipe(
      select(selectCurrentUserId),
      filter(inputIsNotNullOrUndefined),
      switchMap(userId =>
        collectionData(collection(this.firestore, `data/${userId}/actionItem`), { idField: 'id' }).pipe(
          takeWhile(() => !!this.userService.currentUser),
        )
      )
    );
  }
}
