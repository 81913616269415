import { Action, createReducer, on } from '@ngrx/store';
import {
  getUserActivityLogsAction, getUserActivityLogsSuccessAction, getUserActivityLogsFailureAction,
} from './get-user-activity-logs.actions';
import { GetUserActivityLogsState } from './get-user-activity-logs.types';

const initialState = {
  loading: false,
  loaded: false,
  data: null,
  error: null,
};

const getUserActivityLogsReducer = createReducer<GetUserActivityLogsState>(
  initialState,
  on(
    getUserActivityLogsAction,
    state => ({
      ...state,
      loading: true,
      loaded: false,
    })
  ),
  on(
    getUserActivityLogsSuccessAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      data: action.data,
      error: null,
    })
  ),
  on(
    getUserActivityLogsFailureAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      data: null,
      error: action.error,
    })
  ),
);

export const reducer = (state: GetUserActivityLogsState, action: Action) => getUserActivityLogsReducer(state, action);
