import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { UploadDocumentEffects } from './store/upload-document.effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/upload-document.reducers';

@NgModule({
  imports: [
    EffectsModule.forFeature([ UploadDocumentEffects ]),
    StoreModule.forFeature('uploadDocument', reducer),
  ],
})
export class UploadDocumentModule {
}
