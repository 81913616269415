import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/current-user.reducers';
import { CurrentUserEffects } from './store/current-user.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([ CurrentUserEffects ]),
    StoreModule.forFeature('currentUser', reducer),
  ]
})
export class CurrentUserModule { }
