import { createAction, props } from '@ngrx/store';
import { ActionTypes } from './action-types';
import { Account } from '../get-accounts.service';

export const getAccountsAction = createAction(
  ActionTypes.GET_ACCOUNTS,
  props<{ userId?: string | string[] }>()
);

export const getAccountsSuccessAction = createAction(
  ActionTypes.GET_ACCOUNTS_SUCCESS,
  props<{ locations: Account[] }>()
);

export const getAccountsDistinctSuccessAction = createAction(
  ActionTypes.GET_ACCOUNTS_DISTINCT_SUCCESS,
  props<{ locationsDistinct: { [name: string]: Account } }>()
);

export const getAccountsFailureAction = createAction(
  ActionTypes.GET_ACCOUNTS_FAILURE,
  props<{ error: any }>()
);
