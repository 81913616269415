import { Action, createReducer, on } from '@ngrx/store';
import {
  getRelatedUsersAction, getRelatedUsersFailureAction, getRelatedUsersSuccessAction,
} from './get-related-users.actions';
import { GetRelatedUsersState } from './get-related-users.types';

const initialState = {
  loading: false,
  loaded: false,
  users: null,
  error: null,
};

const getRelatedUsersReducer = createReducer<GetRelatedUsersState>(
  initialState,
  on(
    getRelatedUsersAction,
    state => ({
      ...state,
      loading: true,
      loaded: false,
    })
  ),
  on(
    getRelatedUsersSuccessAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      users: action.users,
      error: null,
    })
  ),
  on(
    getRelatedUsersFailureAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    })
  ),
);

export const reducer = (state: GetRelatedUsersState, action: Action) => getRelatedUsersReducer(state, action);
