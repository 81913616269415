import { Component, Inject, Renderer2 } from '@angular/core';
import { connectFunctionsEmulator, Functions } from '@angular/fire/functions';
import { environment } from '../environments/environment';
import { select, Store } from '@ngrx/store';
import { DOCUMENT } from '@angular/common';
import { selectGetCompanySettingsTheme } from './company-settings/get-company-settings/store/get-company-settings.selectors';
import { NgbConfig, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { LoadingAppService } from './core/services/loading-app.service';
import { connectFirestoreEmulator, Firestore, } from "@angular/fire/firestore";
import { Auth, connectAuthEmulator } from "@angular/fire/auth";
import { connectStorageEmulator, Storage } from "@angular/fire/storage";
import { PageMetaService } from './core/page-meta.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
})
export class AppComponent {

  constructor(
    private functions: Functions,
    private firestore: Firestore,
    private storage: Storage,
    private auth: Auth,
    private renderer: Renderer2,
    private store$: Store,
    @Inject(DOCUMENT) private document: Document,
    ngbConfig: NgbConfig,
    NgbModalConfig: NgbModalConfig,
    private loadingAppService: LoadingAppService,
    private pageMetaService: PageMetaService,
  ) {
    ngbConfig.animation = false;
    NgbModalConfig.backdrop = 'static';
    NgbModalConfig.keyboard = false;

    if (environment.useLocalFunctions && !environment.useEmulators) {
      connectFunctionsEmulator(this.functions, 'localhost', 5001);
    }

    if (environment.useEmulators) {
      connectFunctionsEmulator(this.functions, 'localhost', 5001);
      connectFirestoreEmulator(this.firestore, 'localhost', 8080);
      connectStorageEmulator(this.storage, 'localhost', 9199);
      connectAuthEmulator(this.auth, 'http://127.0.0.1:9099');
    }

    const bodyEl = document.querySelector('body');
    const titleEl = document.querySelector('title');

    this.store$.pipe(select(selectGetCompanySettingsTheme)).subscribe(theme =>
      theme ? this.renderer.setAttribute(bodyEl, 'data-theme', `${ theme }-theme`)
        : this.renderer.removeAttribute(bodyEl, 'data-theme')
    );

    this.loadingAppService.loading$.subscribe(loading => {
      const loadingAppEl = this.document.querySelector('#loading-app');
      if (!loadingAppEl) throw new Error('Unexpected: Missed loading app');
      this.renderer.setStyle(loadingAppEl, 'display', loading ? 'flex' : 'none');
    });

    this.pageMetaService.title$.subscribe(title => {
      this.renderer.removeChild(titleEl, titleEl?.childNodes[0]);
      this.renderer.appendChild(titleEl, this.renderer.createText(title));
    });
  }

}
