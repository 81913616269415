import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { GetRelatedUsersEffects } from './store/get-related-users.effects';
import { reducer } from './store/get-related-users.reducers';
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([ GetRelatedUsersEffects ]),
    StoreModule.forFeature('getRelatedUsers', reducer),
  ]
})
export class GetRelatedUsersModule {}
