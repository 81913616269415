import { Action, createReducer, on } from '@ngrx/store';
import { RemoveReportFiltersState } from './remove-report-filters.types';
import { removeReportFiltersAction, removeReportFiltersSuccessAction, removeReportFiltersFailureAction } from './remove-report-filters.actions';

const initialState = {
  updating: false,
  updated: false,
  data: null,
  error: null,
};

const removeReportFiltersReducer = createReducer<RemoveReportFiltersState>(
  initialState,
  on(
    removeReportFiltersAction,
    state => ({
      ...state,
      updating: true,
      updated: false,
    })
  ),
  on(
    removeReportFiltersSuccessAction,
    (state, action) => ({
      ...state,
      updating: false,
      updated: true,
      data: action.data,
      error: null,
    })
  ),
  on(
    removeReportFiltersFailureAction,
    (state, action) => ({
      ...state,
      updating: false,
      updated: false,
      error: action.error,
    })
  ),
);

export const reducer = (state: RemoveReportFiltersState, action: Action) => removeReportFiltersReducer(state, action);
