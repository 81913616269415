import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GetTutorialSettingsService } from '../get-tutorial-settings.service';
import {
  getTutorialSettingsAction,
  getTutorialSettingsSuccessAction,
  getTutorialSettingsFailureAction,
} from './get-tutorial-settings.actions';

@Injectable()
export class GetTutorialSettingsEffects {
  getTutorialSettings$ = createEffect(() => this.actions$.pipe(
    ofType(getTutorialSettingsAction),
    switchMap(action =>
      this.getTutorialSettingsService.get(action.payload).pipe(
        map(data => getTutorialSettingsSuccessAction({ data })),
        catchError(error => of(getTutorialSettingsFailureAction({ error }))),
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private getTutorialSettingsService: GetTutorialSettingsService,
  ) {
  }
}
