import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StartTrialEffects } from './store/start-trial.effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/start-trial.reducers';

@NgModule({
  imports: [
    EffectsModule.forFeature([StartTrialEffects]),
    StoreModule.forFeature('startTrial', reducer),
  ],
})
export class StartTrialModule {}
