import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { GetActionItemsEffects } from './store/get-action-items.effects';
import { reducer } from './store/get-action-items.reducers';
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([ GetActionItemsEffects ]),
    StoreModule.forFeature('getActionItems', reducer),
  ]
})
export class GetActionItemsModule {}
