import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './store/user.effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/user.reducers';

@NgModule({
  imports: [
    EffectsModule.forFeature([ UserEffects ]),
    StoreModule.forFeature('users', reducer),
  ]
})
export class GetUsersStoreModule {}
