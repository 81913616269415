import { Observable, of, switchMap, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { getDownloadURL, ref, Storage, uploadBytes } from '@angular/fire/storage';
import { select, Store } from '@ngrx/store';
import { selectCurrentUserAccountId } from '../../current-user/store/current-user.selectors';
import { filter } from 'rxjs/operators';
import { inputIsNotNullOrUndefined } from '../../data-access/input-is-not-null-or-undefined';
import { UploadResult } from '@firebase/storage';

export interface UploadPayload {
  file: File,
  fileName: string,
  replace?: boolean,
}

@Injectable({
  providedIn: 'root',
})
export class UploadDocumentService {
  path = 'files';

  constructor(private storage: Storage, private store: Store) {
  }

  upload(payload: UploadPayload): Observable<UploadResult> {
    return this.store.pipe(select(selectCurrentUserAccountId)).pipe(
      filter(inputIsNotNullOrUndefined),
      switchMap(accountId => {
        const fileRef = ref(this.storage, `${ this.path }/${ accountId }/${ payload.fileName }`);

        if (payload.replace) {
          return of({
            ref: fileRef,
            message: 'File is not exist',
            isExist: false,
          });
        }

        return getDownloadURL(fileRef)
          .then(() => {
            return {
              ref: fileRef,
              message: 'File is exist',
              isExist: true,
            };
          })
          .catch(() => {
            return {
              ref: fileRef,
              message: 'File is not exist',
              isExist: false,
            };
          });
      }),
      switchMap(data => {
        if (payload.file && data.isExist) {
          return throwError(() => 'The file with name already exists. Enter a different name.');
        }
        return uploadBytes(data.ref, payload.file);
      }),
    );
  }
}
