import { createAction, props } from '@ngrx/store';
import { ActionTypes } from './action-types';

export const updateAccountVisitsAction = createAction(
  ActionTypes.UPDATE_ACCOUNT_VISITS,
  props<{ accountId: string }>()
);

export const updateAccountVisitsSuccessAction = createAction(
  ActionTypes.UPDATE_ACCOUNT_VISITS_SUCCESS,
  props<{ data: any }>()
);

export const updateAccountVisitsFailureAction = createAction(
  ActionTypes.UPDATE_ACCOUNT_VISITS_FAILURE,
  props<{ error: any }>()
);
