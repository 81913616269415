import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/update-account-visits.reducers';
import { UpdateAccountVisitsEffects } from './store/update-account-visits.effects';

@NgModule({
  imports: [
    EffectsModule.forFeature([UpdateAccountVisitsEffects]),
    StoreModule.forFeature('updateAccountVisits', reducer),
  ],
})
export class UpdateAccountVisitsModule {}
