import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountDetailsLayoutComponent } from './account-details-layout.component';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FlexModule } from 'ngx-flexible-layout';
import { UpdateAccountVisitsModule } from '../../user-history/update-account-visits/update-account-visits.module';
import { TourNgBootstrapModule } from 'ngx-ui-tour-ng-bootstrap';
import { SharedModule } from "../../shared/shared.module";
import { LetDirective } from "@ngrx/component";
import { ScopeCaptionPipe } from '../../shared/pipes/scope-caption.pipe';
import { AccountVisibilityService } from '../account-visibility.service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { GetChildAccountsModule } from '../get-child-accounts/get-child-accounts.module';

@NgModule({
  declarations: [ AccountDetailsLayoutComponent ],
  imports: [
    CommonModule,
    NgbNavModule,
    RouterModule,
    FlexModule,
    UpdateAccountVisitsModule,
    TourNgBootstrapModule,
    SharedModule,
    LetDirective,
    ScopeCaptionPipe,
    GetChildAccountsModule,
    NgxPermissionsModule,
  ],
  providers: [ AccountVisibilityService ],
})
export class AccountDetailsLayoutModule {
}
