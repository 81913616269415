import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { updateAccountVisitsAction, updateAccountVisitsSuccessAction, updateAccountVisitsFailureAction } from './update-account-visits.actions';
import { UpdateAccountVisitsService } from '../update-account-visits.service';

@Injectable()
export class UpdateAccountVisitsEffects {
  updateAccountVisits$ = createEffect(() => this.actions$.pipe(
    ofType(updateAccountVisitsAction),
    switchMap(action =>
      this.updateAccountVisitsService.update(action.accountId).pipe(
        map(data => updateAccountVisitsSuccessAction({ data })),
        catchError(error => of(updateAccountVisitsFailureAction({ error }))),
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private updateAccountVisitsService: UpdateAccountVisitsService,
  ) {
  }
}
