import { Injectable } from '@angular/core';
import { combineLatest, Observable, switchMap, takeWhile } from 'rxjs';
import { UserService } from '../../auth/services/user.service';
import { select, Store } from '@ngrx/store';
import { Firestore } from '@angular/fire/firestore';
import { getUsersActivityLogStats$, UserActivityLogStats } from '../../data-access/user-activity-logs';
import { selectCurrentUserCompanyId } from '../../current-user/store/current-user.selectors';
import { filter, take } from 'rxjs/operators';
import { inputIsNotNullOrUndefined } from '../../data-access/input-is-not-null-or-undefined';
import { selectGetRelatedUserIds } from '../get-related-users/store/get-related-users.selectors';

export type GetUsersActionEventStatsPayload = {
  dateRange: {
    from: number,
    to: number,
  },
}

@Injectable({
  providedIn: 'root',
})
export class GetUsersActivityLogStatsService {
  constructor(private firestore: Firestore, private store$: Store, private userService: UserService) {
  }

  get(payload: GetUsersActionEventStatsPayload): Observable<UserActivityLogStats[]> {
    return combineLatest([
      this.store$.pipe(
        select(selectCurrentUserCompanyId),
        filter(inputIsNotNullOrUndefined),
      ),
      this.store$.pipe(select(selectGetRelatedUserIds)),
    ]).pipe(
      take(1),
      switchMap(([ companyId, userIds ]) =>
        getUsersActivityLogStats$(this.firestore, companyId, userIds, payload).pipe(
          takeWhile(() => !!this.userService.currentUser),
        )
      )
    );
  }
}
