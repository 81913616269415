import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GetPurchaseService } from '../get-purchase.service';
import { getPurchaseAction, getPurchaseSuccessAction, getPurchaseFailureAction } from './get-purchase.actions';

@Injectable()
export class GetPurchaseEffects {
  getPurchase$ = createEffect(() => this.actions$.pipe(
    ofType(getPurchaseAction),
    switchMap(action =>
      this.getPurchaseService.get().pipe(
        map(purchase => getPurchaseSuccessAction({ purchase })),
        catchError(error => of(getPurchaseFailureAction({ error }))),
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private getPurchaseService: GetPurchaseService,
  ) {
  }
}
