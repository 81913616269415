import { Injectable } from '@angular/core';
import { UserService } from '../auth/services/user.service';
import { Observable, takeWhile } from 'rxjs';
import { getCurrentUser$, UserRelation } from '../data-access/users';
import { Firestore } from '@angular/fire/firestore';
import { PersonalInfo } from '../data-access/personal-info';

export interface CurrentUser extends UserRelation, PersonalInfo {}

@Injectable({
  providedIn: 'root',
})
export class GetCurrentUserService {
  constructor(private userService: UserService, private firestore: Firestore) {
  }

  get(uid: string): Observable<CurrentUser> {
    return getCurrentUser$(this.firestore, uid).pipe(
      takeWhile(() => !!this.userService.currentUser),
    );
  }
}
