import { defer, from, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Functions, httpsCallable } from '@angular/fire/functions';

export interface UpdateSubscriptionPayload {
  productId: string,
  quantity: number,
}

export interface UpdateSubscriptionResponse {
  "status": string,
  "statusUpdateTime": string,
  "id": string,
  "planId": string,
  "startTime": string,
  "quantity": string,
  "subscriber": object,
  "updateTime": string,
  "planOverridden": boolean,
  links: { href: string, rel: string, method: string }[],
}

@Injectable({
  providedIn: 'root',
})
export class UpdateSubscriptionService {
  constructor(private functions: Functions) {
  }

  update(payload: UpdateSubscriptionPayload): Observable<boolean> {
    return defer(() =>
      httpsCallable<UpdateSubscriptionPayload, boolean>(this.functions, 'updateSubscription')(payload)
    ).pipe(
      map(response => response.data),
    );
  }
}
