import { NgModule } from '@angular/core';
import { OutlookAuthModule } from './outlook-auth/outlook-auth.module';
import { MSAL_INSTANCE, MsalModule, MsalService } from '@azure/msal-angular';
import { BrowserCacheLocation, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { OAuthSettings } from '../../outlook-oauth';
import { GetOutlookCalendarModule } from './get-outlook-calendar/get-outlook-calendar.module';

let msalInstance: IPublicClientApplication | undefined = undefined;

export function MSALInstanceFactory(): IPublicClientApplication {
  msalInstance = msalInstance ?? new PublicClientApplication({
    auth: {
      clientId: OAuthSettings.appId,
      redirectUri: OAuthSettings.redirectUri,
      postLogoutRedirectUri: OAuthSettings.redirectUri
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    }
  });

  return msalInstance;
}

@NgModule({
  imports: [
    MsalModule,
    OutlookAuthModule,
    GetOutlookCalendarModule,
  ],
  providers: [
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    MsalService
  ],
})
export class OutlookIntegrationModule {}
