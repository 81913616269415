import { createAction, props } from '@ngrx/store';
import { ActionTypes } from './action-types';
import { ActionItem } from '../get-action-items.service';

export const getActionItemsAction = createAction(
  ActionTypes.GET_ACTION_ITEMS
);

export const getActionItemsSuccessAction = createAction(
  ActionTypes.GET_ACTION_ITEMS_SUCCESS,
  props<{ data: ActionItem[] }>()
);

export const getActionItemsFailureAction = createAction(
  ActionTypes.GET_ACTION_ITEMS_FAILURE,
  props<{ error: any }>()
);
