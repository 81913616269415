import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GetIntegrationsService } from '../get-integrations.service';
import { getIntegrationsAction, getIntegrationsSuccessAction, getIntegrationsFailureAction } from './get-integrations.actions';

@Injectable()
export class GetIntegrationsEffects {
  getIntegrations$ = createEffect(() => this.actions$.pipe(
    ofType(getIntegrationsAction),
    switchMap(action =>
      this.getIntegrationsService.get().pipe(
        map(integrations => getIntegrationsSuccessAction({ integrations })),
        catchError(error => of(getIntegrationsFailureAction({ error }))),
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private getIntegrationsService: GetIntegrationsService,
  ) {
  }
}
