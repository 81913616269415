import { Action, createReducer, on } from '@ngrx/store';
import {
  getAccountsAction,
  getAccountsSuccessAction,
  getAccountsFailureAction,
  getAccountsDistinctSuccessAction,
} from './get-accounts.actions';
import { GetAccountsState } from './get-accounts.types';

const initialState = {
  loading: false,
  loaded: false,
  locations: null,
  distinct: null,
  error: null,
};

const getAccountsReducer = createReducer<GetAccountsState>(
  initialState,
  on(
    getAccountsAction,
    state => ({
      ...state,
      loading: true,
      loaded: false,
      error: null,
    })
  ),
  on(
    getAccountsSuccessAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      locations: action.locations,
      error: null,
    })
  ),
  on(
    getAccountsDistinctSuccessAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      distinct: action.locationsDistinct,
      error: null,
    })
  ),
  on(
    getAccountsFailureAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      locations: null,
      distinct: null,
      error: action.error,
    })
  ),
);

export const reducer = (state: GetAccountsState, action: Action) => getAccountsReducer(state, action);
