import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GetActionItemsService } from '../get-action-items.service';
import {
  getActionItemsAction,
  getActionItemsFailureAction,
  getActionItemsSuccessAction
} from './get-action-items.actions';

@Injectable()
export class GetActionItemsEffects {
  getActionItems$ = createEffect(() => this.actions$.pipe(
    ofType(getActionItemsAction),
    switchMap(() =>
      this.getActionItemsService.get().pipe(
        map(data => getActionItemsSuccessAction({data})),
        catchError(error => of(getActionItemsFailureAction({error}))),
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private getActionItemsService: GetActionItemsService,
  ) {
  }
}
