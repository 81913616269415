import { Action, createReducer, on } from '@ngrx/store';
import { UpdateLocalSettingsState } from './update-local-settings.types';
import { updateLocalSettingsAction, updateLocalSettingsSuccessAction, updateLocalSettingsFailureAction } from './update-local-settings.actions';

const initialState = {
  updating: false,
  updated: false,
  data: null,
  error: null,
};

const updateLocalSettingsReducer = createReducer<UpdateLocalSettingsState>(
  initialState,
  on(
    updateLocalSettingsAction,
    state => ({
      ...state,
      updating: true,
      updated: false,
    })
  ),
  on(
    updateLocalSettingsSuccessAction,
    (state, action) => ({
      ...state,
      updating: false,
      updated: true,
      data: action.data,
      error: null,
    })
  ),
  on(
    updateLocalSettingsFailureAction,
    (state, action) => ({
      ...state,
      updating: false,
      updated: false,
      error: action.error,
    })
  ),
);

export const reducer = (state: UpdateLocalSettingsState, action: Action) => updateLocalSettingsReducer(state, action);
