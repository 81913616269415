import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, of, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GetUsersService } from '../get-users.service';
import {
  addUserAction,
  addUserFailureAction,
  addUserSuccessAction,
  deleteUserAction,
  deleteUserFailureAction,
  deleteUserSuccessAction,
  updateUserAction,
  updateUserFailureAction,
  updateUserSuccessAction
} from './user.actions';
import { ToastrService } from 'ngx-toastr';
import { User } from '../get-related-users/get-related-users.service';

@Injectable()
export class UserEffects {
  /*  getUsers$ = createEffect(() => this.actions$.pipe(
      ofType(getUsersAction),
      switchMap(action =>
        this.getUsersService.get(action.payload).pipe(
          map(userResponse => getUsersSuccessAction({ userResponse })),
          catchError(error => of(getUsersFailureAction({ error }))),
        )
      )
    ));*/

  addUser$ = createEffect(() => this.actions$.pipe(
    ofType(addUserAction),
    exhaustMap(action =>
      this.getUsersService.create(action.payload).pipe(
        map((user: User) => addUserSuccessAction({ user })),
        catchError(error => of(addUserFailureAction({ error }))),
      )
    )
  ));

  addUserSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(addUserSuccessAction),
    tap(() => this.notifyService.success('New user added successfully'))
  ), { dispatch: false });

  addUserFailure$ = createEffect(() => this.actions$.pipe(
    ofType(addUserFailureAction),
    tap(({ error }) => this.notifyService.error(error.message || 'Failed to add new user'))
  ), { dispatch: false });

  updateUser$ = createEffect(() => this.actions$.pipe(
    ofType(updateUserAction),
    exhaustMap(action =>
      this.getUsersService.update(action.payload).pipe(
        map((user: User) => updateUserSuccessAction({ user })),
        catchError(error => of(updateUserFailureAction({ error }))),
      )
    )
  ));

  updateUserSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(updateUserSuccessAction),
    tap(() => this.notifyService.success('User updated successfully'))
  ), { dispatch: false });

  updateUserFailure$ = createEffect(() => this.actions$.pipe(
    ofType(updateUserFailureAction),
    tap(({ error }) => this.notifyService.error(error.message || 'Failed to update user'))
  ), { dispatch: false });

  deleteUser$ = createEffect(() => this.actions$.pipe(
    ofType(deleteUserAction),
    exhaustMap(action =>
      this.getUsersService.delete(action.id).pipe(
        map(() => deleteUserSuccessAction()),
        catchError(error => of(deleteUserFailureAction({ error }))),
      )
    )
  ));

  deleteUserSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(deleteUserSuccessAction),
    tap(() => this.notifyService.success('User deleted successfully'))
  ), { dispatch: false });

  deleteUserFailure$ = createEffect(() => this.actions$.pipe(
    ofType(deleteUserFailureAction),
    tap(({ error }) => this.notifyService.error(error.message || 'Failed to delete user'))
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private getUsersService: GetUsersService,
    private notifyService: ToastrService,
  ) {
  }
}
