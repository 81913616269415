import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  getAccountAction,
  getAccountFailureAction,
  getAccountSuccessAction
} from './get-account.actions';
import { GetAccountService } from '../get-account.service';

@Injectable()
export class GetAccountEffects {
  getAccount$ = createEffect(() => this.actions$.pipe(
    ofType(getAccountAction),
    switchMap(action =>
      this.getAccountService.get(action.id).pipe(
        map(account => getAccountSuccessAction({ account })),
        catchError(error => of(getAccountFailureAction({ error }))),
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private getAccountService: GetAccountService,
  ) {
  }
}
