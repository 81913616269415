import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { UploadAttachmentsEffects } from './store/upload-attachments.effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/upload-attachments.reducers';

@NgModule({
  imports: [
    EffectsModule.forFeature([ UploadAttachmentsEffects ]),
    StoreModule.forFeature('uploadAttachments', reducer),
  ],
})
export class UploadAttachmentsModule {
}
