import { Observable, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';
import { deleteDoc, doc, Firestore } from '@angular/fire/firestore';
import { select, Store } from '@ngrx/store';
import { selectCurrentUserId } from '../../current-user/store/current-user.selectors';
import { filter, map, take } from 'rxjs/operators';
import { inputIsNotNullOrUndefined } from '../../data-access/input-is-not-null-or-undefined';

export interface RemoveReportFiltersPayload {
  id: string,
  scope: 'dailyRoutes' | 'accounts' | 'activity',
}

@Injectable({
  providedIn: 'root',
})
export class RemoveReportFiltersService {
  constructor(private firestore: Firestore, private store$: Store) {
  }

  remove(payload: RemoveReportFiltersPayload): Observable<{ success: boolean }> {
    return this.store$.pipe(
      select(selectCurrentUserId),
      filter(inputIsNotNullOrUndefined),
      switchMap(userId =>
        deleteDoc(doc(this.firestore, `reportFilters/${ userId }/${ payload.scope }/${ payload.id }`))
      ),
      map(() => ({ success: true })),
      take(1),
    );
  }
}
