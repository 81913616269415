import { Injectable } from '@angular/core';
import { Observable, takeWhile } from 'rxjs';
import { collection, Firestore, collectionData } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import { UserService } from '../../../auth/services/user.service';
import { TutorialStep } from '../../../core/services/tutorial.service';

@Injectable({
  providedIn: 'root',
})
export class GetTutorialSettingsService {

  constructor(private firestore: Firestore, private store$: Store, private userService: UserService) {
  }

  get(payload: { role: string }): Observable<TutorialStep[]> {
    return collectionData(collection(this.firestore, `tutorialSettings/${payload.role}/steps`)).pipe(
      takeWhile(() => !!this.userService.currentUser),
    );
  }
}
