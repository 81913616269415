import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { GetReportFiltersEffects } from './store/get-report-filters.effects';
import { reducer } from './store/get-report-filters.reducers';
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([ GetReportFiltersEffects ]),
    StoreModule.forFeature('getReportFilters', reducer),
  ]
})
export class GetReportFiltersModule {}
