import { Action, createReducer, on } from '@ngrx/store';
import {
  getActionItemsAction, getActionItemsFailureAction, getActionItemsSuccessAction,
} from './get-action-items.actions';
import { GetActionItemsState } from './get-action-items.types';

const initialState = {
  loading: false,
  loaded: false,
  data: null,
  error: null,
};

const getActionItemsReducer = createReducer<GetActionItemsState>(
  initialState,
  on(
    getActionItemsAction,
    state => ({
      ...state,
      loading: true,
      loaded: false,
    })
  ),
  on(
    getActionItemsSuccessAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      data: action.data,
      error: null,
    })
  ),
  on(
    getActionItemsFailureAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    })
  ),
);

export const reducer = (state: GetActionItemsState, action: Action) => getActionItemsReducer(state, action);
