import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/get-users-activity-log-stats.reducers';
import { GetUsersActivityLogStatsEffects } from './store/get-users-activity-log-stats.effects';

@NgModule({
  imports: [
    EffectsModule.forFeature([ GetUsersActivityLogStatsEffects ]),
    StoreModule.forFeature('getUsersActivityLogStats', reducer),
  ],
})
export class GetUsersActivityLogStatsModule {
}
