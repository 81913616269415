import { Action, createReducer, on } from '@ngrx/store';
import { CreateSubscriptionState } from './create-subscription.types';
import { createSubscriptionAction, createSubscriptionSuccessAction, createSubscriptionFailureAction } from './create-subscription.actions';

const initialState = {
  loading: false,
  loaded: false,
  data: null,
  error: null,
};

const createSubscriptionReducer = createReducer<CreateSubscriptionState>(
  initialState,
  on(
    createSubscriptionAction,
    state => ({
      ...state,
      loading: true,
      loaded: false,
      data: null,
      error: null,
    })
  ),
  on(
    createSubscriptionSuccessAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      data: action.data,
    })
  ),
  on(
    createSubscriptionFailureAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    })
  ),
);

export const reducer = (state: CreateSubscriptionState, action: Action) => createSubscriptionReducer(state, action);
