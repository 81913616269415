import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { collection, Firestore, collectionData } from '@angular/fire/firestore';
import { select, Store } from '@ngrx/store';
import { selectCurrentUserId } from '../../current-user/store/current-user.selectors';
import { filter, map } from 'rxjs/operators';
import { inputIsNotNullOrUndefined } from '../../data-access/input-is-not-null-or-undefined';
import { ColumnValue } from '../column-settings/column-settings.component';
import { FilterItem } from '../reports.component';

export interface ReportFilter {
  id: string,
  name: string,
  data: {
    columnSettingValues: ColumnValue[],
    filterValues: FilterItem[],
    selectedFilterValues: FilterItem[],
  },
  scope: 'dailyRoutes' | 'accounts' | 'activity',
}

@Injectable({
  providedIn: 'root',
})
export class GetReportFiltersService {

  constructor(private firestore: Firestore, private store$: Store) {
  }

  get(scope: 'dailyRoutes' | 'accounts' | 'activity' = 'dailyRoutes'): Observable<ReportFilter[]> {
    return <Observable<ReportFilter[]>>this.store$.pipe(
      select(selectCurrentUserId),
      filter(inputIsNotNullOrUndefined),
      switchMap(userId =>
        collectionData(collection(this.firestore, `reportFilters/${userId}/${scope}`), {idField: 'id'})
      ),
      map(filters => filters.map(filter => ({
        ...filter,
        scope,
      })))
    );
  }
}
