import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { getUserActivityLogsAction, getUserActivityLogsSuccessAction, getUserActivityLogsFailureAction } from './get-user-activity-logs.actions';
import { GetUserActivityLogsService } from '../get-user-activity-logs.service';

@Injectable()
export class GetUserActivityLogsEffects {
  getUserActivityLogs$ = createEffect(() => this.actions$.pipe(
    ofType(getUserActivityLogsAction),
    switchMap(action =>
      this.getUserActivityLogsService.get(action.payload).pipe(
        map(data => getUserActivityLogsSuccessAction({ data })),
        catchError(error => of(getUserActivityLogsFailureAction({ error }))),
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private getUserActivityLogsService: GetUserActivityLogsService,
  ) {
  }
}
