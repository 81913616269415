import { defer, from, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { camelCase, isPlainObject, mapKeys, mapValues, snakeCase } from 'lodash';
import { environment } from '../../../environments/environment';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { map } from 'rxjs/operators';

export interface CreateSubscriptionPayload {
  productId: string,
  quantity: number,
  successUrl: string,
  cancelUrl: string,
}

export interface CreateSubscriptionResponse {
  url: string,
}

const mapKeysDeepLodash = (obj: any, cb?: any, isRecursive?: boolean): any => {
  if (!obj && !isRecursive) {
    return {};
  }

  if (!isRecursive) {
    if (typeof obj === 'string' || typeof obj === 'number' || typeof obj === 'boolean') {
      return {};
    }
  }

  if (Array.isArray(obj)) {
    return obj.map(item => {
      return mapKeysDeepLodash(item, cb, true);
    });
  }

  if (!isPlainObject(obj)) {
    return obj;
  }

  const result = mapKeys(obj, cb);

  return mapValues(result, value => {
    return mapKeysDeepLodash(value, cb, true);
  });
};

export const toCamelCase = (data: any) => {
  return mapKeysDeepLodash(data, (value: any, key: any) => camelCase(key));
}

export const toSnakeCase = (data: any) => {
  return mapKeysDeepLodash(data, (value: any, key: any) => snakeCase(key));
}

@Injectable({
  providedIn: 'root',
})
export class CreateSubscriptionService {
  constructor(private functions: Functions) {
  }

  create(payload: CreateSubscriptionPayload): Observable<boolean> {
    return defer(() =>
      httpsCallable<CreateSubscriptionPayload, boolean>(this.functions, 'createPaymentSession')(payload)
    ).pipe(
      map(response => response.data),
    );
  }
}
