import { Action, createReducer, on } from '@ngrx/store';
import {
  getTutorialProgressAction, getTutorialProgressFailureAction, getTutorialProgressSuccessAction,
} from './get-tutorial-progress.actions';
import { GetTutorialProgressState } from './get-tutorial-progress.types';

const initialState = {
  loading: false,
  loaded: false,
  tutorialProgress: null,
  error: null,
};

const getTutorialProgressReducer = createReducer<GetTutorialProgressState>(
  initialState,
  on(
    getTutorialProgressAction,
    state => ({
      ...state,
      loading: true,
      loaded: false,
    })
  ),
  on(
    getTutorialProgressSuccessAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      tutorialProgress: action.tutorialProgress,
      error: null,
    })
  ),
  on(
    getTutorialProgressFailureAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    })
  ),
);

export const reducer = (state: GetTutorialProgressState, action: Action) => getTutorialProgressReducer(state, action);
