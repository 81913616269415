import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UpdateSubscriptionService } from '../update-subscription.service';
import {
  updateSubscriptionAction,
  updateSubscriptionFailureAction,
  updateSubscriptionSuccessAction
} from './update-subscription.actions';

@Injectable()
export class UpdateSubscriptionEffects {
  updateSubscription$ = createEffect(() => this.actions$.pipe(
    ofType(updateSubscriptionAction),
    switchMap(action =>
      this.updateSubscriptionService.update(action.payload).pipe(
        map(data => updateSubscriptionSuccessAction({ data })),
        catchError(error => of(updateSubscriptionFailureAction({ error }))),
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private updateSubscriptionService: UpdateSubscriptionService,
  ) {
  }
}
