import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuthGuardModule } from '@angular/fire/compat/auth-guard';
import { SharedModule } from './shared/shared.module';
import { RedirectComponent } from './redirect.component';
import { ToastNoAnimationModule } from 'ngx-toastr';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { LayoutModule } from './layout/layout.module';
import { CurrentUserModule } from './current-user/current-user.module';
import { AccountDetailsLayoutModule } from './accounts/account-details-layout/account-details-layout.module';
import { GetAccountModule } from './accounts/get-account/get-account.module';
import { GetUsersStoreModule } from './users/get-users-store.module';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { UpdateAccountModule } from './accounts/update-account/update-account.module';
import { GetAccountCategoriesModule } from './accounts/categories/get-account-categories/get-account-categories.module';
import { DateFnsModule, FormatDistanceToNowPipeModule } from 'ngx-date-fns';
import { LetDirective, PushPipe } from '@ngrx/component';
import { HasNewMessagesModule } from './messages/has-new-messages/has-new-messages.module';
import { SendMessageModule } from './messages/send-message/send-message.module';
import { UploadDocumentModule } from './documents/upload-document/upload-document.module';
import { CoreModule } from './core/core.module';
import { GetPurchaseModule } from './purchase/get-purchase/get-purchase.module';
import { GetCompanySettingsModule } from './company-settings/get-company-settings/get-company-settings.module';
import { GetCompanyUsersModule } from './company/get-company-users/get-company-users.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TourNgBootstrapModule } from 'ngx-ui-tour-ng-bootstrap';
import { TutorialModule } from './tutorial/tutorial.module';
import { GetAccountsModule } from './accounts/get-accounts/get-accounts.module';
import { GetTagsModule } from './accounts/tags/get-tags/get-tags.module';
import { CreateTagModule } from './accounts/tags/create-tag/create-tag.module';
import { GetReportFiltersModule } from './reports/get-report-filters/get-report-filters.module';
import { GetActionItemsModule } from './users/action-items/get-action-items/get-action-items.module';
import { UpdateLocalSettingsModule } from './local-settings/update-local-settings/update-local-settings.module';
import { GetLocalSettingsModule } from './local-settings/get-local-settings/get-local-settings.module';
import { GetRelatedUsersModule } from './users/get-related-users/get-related-users.module';
import { GetDailyRoutesModule } from './reports/get-daily-routes/get-daily-routes.module';
import { GetConversationsModule } from './messages/get-conversations/get-conversations.module';
import { OutlookIntegrationModule } from './outlook-integration/outlook-integration.module';
import { GetIntegrationsModule } from './settings/integrations/get-integrations/get-integrations.module';
import { UpdateTagModule } from './accounts/tags/update-tag/update-tag.module';
import { RemoveReportFiltersModule } from './reports/remove-report-filter/remove-report-filters.module';
import { CreateUserActivityLogModule } from './users/create-user-activity-log/create-user-activity-log.module';
import { GetUserActivityLogsModule } from './users/get-user-activity-logs/get-user-activity-logs.module';
import { UploadAttachmentsModule } from './attachments/upload-attachments/upload-attachments.module';
import { GetSignedUrlModule } from './documents/get-signed-url/get-signed-url.module';
import { SelectPlanModule } from "./select-plan/select-plan.module";
import { provideQuillConfig } from 'ngx-quill';
import { GetUsersActivityLogStatsModule } from './users/get-users-activity-log-stats/get-users-activity-log-stats.module';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth, } from '@angular/fire/auth';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { enableMultiTabIndexedDbPersistence, getFirestore, provideFirestore } from '@angular/fire/firestore';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { htmlEditButton } from 'quill-html-edit-button';

@NgModule({
  declarations: [
    AppComponent,
    RedirectComponent,
  ],
  bootstrap: [ AppComponent ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireAuthGuardModule,
    FlexLayoutModule,
    NgbModalModule,
    SharedModule,
    ToastNoAnimationModule.forRoot({
      toastClass: 'ngx-toastr rmv-notify'
    }),
    StoreModule.forRoot({}),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
      connectInZone: true
    }),
    EffectsModule.forRoot([]),
    LayoutModule,
    CurrentUserModule,
    SharedModule,
    AccountDetailsLayoutModule,
    GetAccountModule,
    GetUsersStoreModule,
    UpdateAccountModule,
    GetAccountCategoriesModule,
    GetRelatedUsersModule,
    DateFnsModule.forRoot(),
    LetDirective, PushPipe,
    HasNewMessagesModule,
    SendMessageModule,
    UploadDocumentModule,
    CoreModule,
    GetCompanySettingsModule,
    GetPurchaseModule,
    GetCompanyUsersModule,
    FormatDistanceToNowPipeModule,
    NgxPermissionsModule.forRoot(),
    TourNgBootstrapModule,
    TutorialModule,
    GetAccountsModule,
    GetTagsModule,
    CreateTagModule,
    UpdateTagModule,
    GetReportFiltersModule,
    GetActionItemsModule,
    GetLocalSettingsModule,
    UpdateLocalSettingsModule,
    GetDailyRoutesModule,
    GetConversationsModule,
    OutlookIntegrationModule,
    GetIntegrationsModule,
    RemoveReportFiltersModule,
    CreateUserActivityLogModule,
    GetUserActivityLogsModule,
    UploadAttachmentsModule,
    GetSignedUrlModule,
    SelectPlanModule,
    GetUsersActivityLogStatsModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [ Router ],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [ Sentry.TraceService ],
      multi: true,
    },
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => {
      const firestore = getFirestore();
      if (!environment.useEmulators) {
        void enableMultiTabIndexedDbPersistence(firestore);
      }
      return firestore;
    }),
    provideFunctions(() => getFunctions()),
    provideStorage(() => getStorage()),
    provideHttpClient(withInterceptorsFromDi()),
    provideEnvironmentNgxMask(),
    provideQuillConfig({
      trackChanges: 'all',
      customModules: [
        {
          path: 'modules/htmlEditButton',
          implementation: htmlEditButton,
        },
      ],
      modules: {
        htmlEditButton: {
          toolbar: [ 'htmlEditButton' ],
        },
        toolbar: [
          [ 'bold', 'italic', 'underline', 'strike' ], // toggled buttons
          [ 'blockquote', 'code-block' ],
          [ { header: 1 }, { header: 2 } ], // custom button values
          [ { list: 'ordered' }, { list: 'bullet' } ],
          [ { script: 'sub' }, { script: 'super' } ], // superscript/subscript
          [ { indent: '-1' }, { indent: '+1' } ], // outdent/indent
          [ { direction: 'rtl' } ], // text direction
          [ { size: [ 'small', false, 'large', 'huge' ] } ], // custom dropdown
          [ { header: [ 1, 2, 3, 4, 5, 6, false ] } ],
          [ { color: [] }, { background: [] } ], // dropdown with defaults from theme
          [ { font: [] } ],
          [ { align: [] } ],
          [ 'clean' ], // remove formatting button
          [ 'link', 'video' ], // link and image, video
        ]
      }
    }),
  ]
})
export class AppModule {
}
