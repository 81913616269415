import { Action, createReducer, on } from '@ngrx/store';
import {
  getAccountAction, getAccountFailureAction, getAccountSuccessAction,
} from './get-account.actions';
import { GetAccountState } from './get-account.types';

const initialState = {
  loading: false,
  loaded: false,
  account: null,
  error: null,
};

const getAccountReducer = createReducer<GetAccountState>(
  initialState,
  on(
    getAccountAction,
    state => ({
      ...state,
      loading: true,
      loaded: false,
      account: null,
    })
  ),
  on(
    getAccountSuccessAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      account: action.account,
      error: null,
    })
  ),
  on(
    getAccountFailureAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    })
  ),
);

export const reducer = (state: GetAccountState, action: Action) => getAccountReducer(state, action);
