import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { GetCompanyUsersEffects } from './store/get-company-users.effects';
import { reducer } from './store/get-company-users.reducers';
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([ GetCompanyUsersEffects ]),
    StoreModule.forFeature('getCompanyUsers', reducer),
  ]
})
export class GetCompanyUsersModule {}
