import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GetCompanyUsersService } from '../get-company-users.service';
import {
  getCompanyUsersAction,
  getCompanyUsersSuccessAction,
  getCompanyUsersFailureAction,
} from './get-company-users.actions';

@Injectable()
export class GetCompanyUsersEffects {
  getCompanyUsers$ = createEffect(() => this.actions$.pipe(
    ofType(getCompanyUsersAction),
    switchMap(action =>
      this.getCompanyUsersService.get().pipe(
        map(data => getCompanyUsersSuccessAction({ data })),
        catchError(error => of(getCompanyUsersFailureAction({ error }))),
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private getCompanyUsersService: GetCompanyUsersService,
  ) {
  }
}
