import { Injectable } from '@angular/core';
import { Observable, switchMap, takeWhile } from 'rxjs';
import { UserService } from '../../auth/services/user.service';
import { select, Store } from '@ngrx/store';
import { Firestore } from '@angular/fire/firestore';
import { getUserActivityLogs$, UserActivityEvent } from '../../data-access/user-activity-logs';
import { selectCurrentUserCompanyId } from '../../current-user/store/current-user.selectors';
import { filter } from 'rxjs/operators';
import { inputIsNotNullOrUndefined } from '../../data-access/input-is-not-null-or-undefined';

export type GetUserActionEventPayload = {
  userId: string,
  dateRange: {
    from: number,
    to: number,
  },
} | {
  accountId: string,
  dateRange: {
    from: number,
    to: number,
  },
};

@Injectable({
  providedIn: 'root',
})
export class GetUserActivityLogsService {
  constructor(private firestore: Firestore, private store$: Store, private userService: UserService) {
  }

  get(payload: GetUserActionEventPayload): Observable<UserActivityEvent[]> {
    return this.store$.pipe(
      select(selectCurrentUserCompanyId),
      filter(inputIsNotNullOrUndefined),
      switchMap(companyId =>
        getUserActivityLogs$(this.firestore, companyId, payload).pipe(
          takeWhile(() => !!this.userService.currentUser),
        )
      )
    );
  }
}
