import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-redirect',
  template: '<div>Redirecting...</div>',
})
export class RedirectComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit(): void {
    this.route.queryParamMap.pipe(take(1)).subscribe(params => {
      if (params.get('mode') === 'resetPassword') {
        void this.router.navigate(['/auth/reset-password'], { queryParamsHandling: 'merge' })
      }
    });
  }
}
