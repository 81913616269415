import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { GetIntegrationsEffects } from './store/get-integrations.effects';
import { reducer } from './store/get-integrations.reducers';
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([ GetIntegrationsEffects ]),
    StoreModule.forFeature('getIntegrations', reducer),
  ]
})
export class GetIntegrationsModule {}
