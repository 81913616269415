import { Action, createReducer, on } from '@ngrx/store';
import { UpdateSubscriptionState } from './update-subscription.types';
import { updateSubscriptionAction, updateSubscriptionSuccessAction, updateSubscriptionFailureAction } from './update-subscription.actions';

const initialState: UpdateSubscriptionState = {
  updating: false,
  updated: false,
  data: null,
  error: null,
};

const updateSubscriptionReducer = createReducer<UpdateSubscriptionState>(
  initialState,
  on(
    updateSubscriptionAction,
    state => ({
      ...state,
      updating: true,
      updated: false,
      data: null,
      error: null,
    })
  ),
  on(
    updateSubscriptionSuccessAction,
    (state, action) => ({
      ...state,
      updating: false,
      updated: true,
      data: action.data,
    })
  ),
  on(
    updateSubscriptionFailureAction,
    (state, action) => ({
      ...state,
      updating: false,
      updated: false,
      error: action.error,
    })
  ),
);

export const reducer = (state: UpdateSubscriptionState, action: Action) => updateSubscriptionReducer(state, action);
