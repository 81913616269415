import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UpdateTutorialProgressService } from '../update-tutorial-progress.service';
import { updateTutorialProgressAction, updateTutorialProgressFailureAction, updateTutorialProgressSuccessAction } from './update-tutorial-progress.actions';

@Injectable()
export class UpdateTutorialProgressEffects {
  updateTutorialProgress$ = createEffect(() => this.actions$.pipe(
    ofType(updateTutorialProgressAction),
    switchMap(action =>
      this.updateTutorialProgressService.update(action.payload).pipe(
        map(data => updateTutorialProgressSuccessAction({ data })),
        catchError(error => of(updateTutorialProgressFailureAction({ error }))),
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private updateTutorialProgressService: UpdateTutorialProgressService,
  ) {
  }
}
