import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { filter } from 'rxjs/operators';
import { doc, Firestore, setDoc } from '@angular/fire/firestore';
import { select, Store } from '@ngrx/store';
import { selectCurrentUserId } from '../../current-user/store/current-user.selectors';
import { inputIsNotNullOrUndefined } from '../../data-access/input-is-not-null-or-undefined';
import { getUnixTime } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class UpdateAccountVisitsService {
  constructor(private firestore: Firestore, private store$: Store) {
  }

  update(accountId: string): Observable<any> {
    const document = {[accountId]: getUnixTime(new Date())};
    return this.store$.pipe(
      select(selectCurrentUserId),
      filter(inputIsNotNullOrUndefined),
      switchMap((currentUserId) => setDoc(doc(this.firestore, 'accountVisits', currentUserId), document, {
        merge: true,
      }))
    );
  }
}
