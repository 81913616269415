import { Injectable } from '@angular/core';
import { Observable, switchMap, takeWhile } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { UserService } from '../../auth/services/user.service';
import { selectCurrentUserId } from '../../current-user/store/current-user.selectors';
import { filter } from 'rxjs/operators';
import { inputIsNotNullOrUndefined } from '../../data-access/input-is-not-null-or-undefined';
import { doc, Firestore, docData } from '@angular/fire/firestore';

export interface TutorialProgress {
  steps: string[],
  enabled?: boolean,
  showAfterLogin?: boolean,
}

@Injectable({
  providedIn: 'root',
})
export class GetTutorialProgressService {
  constructor(private firestore: Firestore, private store$: Store, private userService: UserService) {
  }

  get(): Observable<TutorialProgress> {
    return this.store$.pipe(
      select(selectCurrentUserId),
      filter(inputIsNotNullOrUndefined),
      switchMap(currentUserId =>
        docData<any>(doc(this.firestore, 'tutorialProgress', currentUserId)).pipe(
          takeWhile(() => !!this.userService.currentUser),
        ),
      ),
    );
  }
}
