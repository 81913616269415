import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CreateSubscriptionService } from '../create-subscription.service';
import {
  createSubscriptionAction,
  createSubscriptionFailureAction,
  createSubscriptionSuccessAction
} from './create-subscription.actions';

@Injectable()
export class CreateSubscriptionEffects {
  createSubscription$ = createEffect(() => this.actions$.pipe(
    ofType(createSubscriptionAction),
    switchMap(action =>
      this.createSubscriptionService.create(action.payload).pipe(
        map(data => createSubscriptionSuccessAction({ data })),
        catchError(error => of(createSubscriptionFailureAction({ error }))),
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private createSubscriptionService: CreateSubscriptionService,
  ) {
  }
}
