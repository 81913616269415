import { Injectable } from '@angular/core';
import { EMPTY, from, Observable, switchMap } from 'rxjs';
import { OutlookAuthService } from '../outlook-auth/outlook-auth.service';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import { filter, take } from 'rxjs/operators';

const TOP_EVENTS = 9999;

export interface OutlookCalendarEvent extends MicrosoftGraph.Event {}

export interface OutlookCalendarParams {
  start: string,
  end: string,
  timeZone: string,
}

@Injectable({
  providedIn: 'root',
})
export class GetOutlookCalendarService {
  constructor(private authService: OutlookAuthService) {
  }

  get({ start, end, timeZone }: OutlookCalendarParams): Observable<OutlookCalendarEvent[]> {
    return this.authService.authenticated$.pipe(
      filter(authenticated => authenticated),
      switchMap(() => {
        return this.authService.graphClient ? from(
          this.authService.graphClient
            .api('/me/calendarview')
            .header('Prefer', `outlook.timezone="${timeZone}"`)
            .query({
              startDateTime: start,
              endDateTime: end
            })
            .select('subject,start,end,location,bodyPreview,attendees')
            .orderby('start/dateTime')
            .top(TOP_EVENTS)
            .get()
            .then(response => response ? response.value : [])
        ).pipe(take(1)) : EMPTY;
      })
    );
  }
}
