import { Action, createReducer, on } from '@ngrx/store';
import { UpdateTagState } from './update-tag.types';
import { updateTagAction, updateTagSuccessAction, updateTagFailureAction } from './update-tag.actions';

const initialState = {
  updating: false,
  updated: false,
  data: null,
  error: null,
};

const updateTagReducer = createReducer<UpdateTagState>(
  initialState,
  on(
    updateTagAction,
    state => ({
      ...state,
      updating: true,
      updated: false,
    })
  ),
  on(
    updateTagSuccessAction,
    (state, action) => ({
      ...state,
      updating: false,
      updated: true,
      data: action.data,
      error: null,
    })
  ),
  on(
    updateTagFailureAction,
    (state, action) => ({
      ...state,
      updating: false,
      updated: false,
      error: action.error,
    })
  ),
);

export const reducer = (state: UpdateTagState, action: Action) => updateTagReducer(state, action);
