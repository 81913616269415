import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdateTutorialProgressModule } from './update-tutorial-progress/update-tutorial-progress.module';
import { GetTutorialSettingsModule } from './tutorial-settings/get-tutorial-settings/get-tutorial-settings.module';
import { GetTutorialProgressModule } from './get-tutorial-progress/get-tutorial-progress.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    GetTutorialSettingsModule,
    GetTutorialProgressModule,
    UpdateTutorialProgressModule,
  ]
})
export class TutorialModule { }
