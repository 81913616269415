import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { selectCurrentUserId } from '../../current-user/store/current-user.selectors';
import { filter } from 'rxjs/operators';
import { inputIsNotNullOrUndefined } from '../../data-access/input-is-not-null-or-undefined';
import { Observable, switchMap } from 'rxjs';
import { arrayUnion, doc, Firestore, setDoc } from '@angular/fire/firestore';
import { isArray, isUndefined } from 'lodash';

export interface UpdateTutorialProgressPayload {
  stepId?: string | string[],
  enabled?: boolean,
  showAfterLogin?: boolean,
}

@Injectable({
  providedIn: 'root',
})
export class UpdateTutorialProgressService {
  constructor(private firestore: Firestore, private store$: Store) {
  }

  update(payload: UpdateTutorialProgressPayload): Observable<void> {
    return this.store$.pipe(
      select(selectCurrentUserId),
      filter(inputIsNotNullOrUndefined),
      switchMap(currentUserId =>
        setDoc(doc(this.firestore, 'tutorialProgress', currentUserId), {
          ...(payload.stepId ? {steps: isArray(payload.stepId) ? payload.stepId : arrayUnion(payload.stepId)} : {}),
          ...(!isUndefined(payload.enabled) ? {enabled: payload.enabled} : {}),
          ...(!isUndefined(payload.showAfterLogin) ? {showAfterLogin: payload.showAfterLogin} : {}),
        }, {merge: true})
      ),
    );
  }
}
