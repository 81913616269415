import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { Firestore } from '@angular/fire/firestore';
import {
  AccountEventDetails,
  ContactEventDetails,
  createUsersActivity$,
  DailyRouteEventDetails,
  DailyRouteRecurringStopsEventDetails,
  NoteEventDetails,
  TaskEventDetails,
  UserActivityEventAccountActions,
  UserActivityEventAppActions,
  UserActivityEventContactActions,
  UserActivityEventDailyRouteActions,
  UserActivityEventDailyRouteRecurringStopsActions,
  UserActivityEventNoteActions,
  UserActivityEventTaskActions,
  UserActivityEventTypes
} from '../../data-access/user-activity-logs';
import { selectCurrentUser } from '../../current-user/store/current-user.selectors';
import { filter, take } from 'rxjs/operators';
import { inputIsNotNullOrUndefined } from '../../data-access/input-is-not-null-or-undefined';

type CreateUserNoteActionEventPayload = {
  type: UserActivityEventTypes.note,
  action: UserActivityEventNoteActions,
  details: NoteEventDetails,
}

type CreateUserDailyRouteActionEventPayload = {
  type: UserActivityEventTypes.dailyRoute,
  action: UserActivityEventDailyRouteActions,
  details: DailyRouteEventDetails,
}

type CreateUserDailyRouteRecurringStopsActionEventPayload = {
  type: UserActivityEventTypes.dailyRouteRecurringStops,
  action: UserActivityEventDailyRouteRecurringStopsActions,
  details: DailyRouteRecurringStopsEventDetails,
}

type CreateUserContactActionEventPayload = {
  type: UserActivityEventTypes.contact,
  action: UserActivityEventContactActions,
  details: ContactEventDetails,
}

type CreateAccountActionEventPayload = {
  type: UserActivityEventTypes.account,
  action: UserActivityEventAccountActions,
  details: AccountEventDetails,
}

type CreateUserTaskActionEventPayload = {
  type: UserActivityEventTypes.task,
  action: UserActivityEventTaskActions,
  details: TaskEventDetails,
}

type CreateUserAppActionEventPayload = {
  type: UserActivityEventTypes.app,
  action: UserActivityEventAppActions,
}

export type CreateUserActionEventPayload =
  | CreateUserNoteActionEventPayload
  | CreateUserDailyRouteActionEventPayload
  | CreateUserDailyRouteRecurringStopsActionEventPayload
  | CreateUserContactActionEventPayload
  | CreateAccountActionEventPayload
  | CreateUserTaskActionEventPayload
  | CreateUserAppActionEventPayload;

@Injectable({
  providedIn: 'root',
})
export class CreateUserActivityLogService {
  constructor(private firestore: Firestore, private store$: Store) {
  }

  create(payload: CreateUserActionEventPayload): Observable<boolean> {
    return this.store$.pipe(
      select(selectCurrentUser),
      filter(inputIsNotNullOrUndefined),
      take(1),
      switchMap(currentUser =>
        createUsersActivity$(this.firestore, currentUser.accountId, currentUser.userId, payload)
      ),
    );
  }
}
