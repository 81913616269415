import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { getRelatedUsersAction } from '../../users/get-related-users/store/get-related-users.actions';
import { selectGetRelatedUsersLoaded } from '../../users/get-related-users/store/get-related-users.selectors';
import { LoadingAppService } from '../services/loading-app.service';

@Injectable({ providedIn: 'root' })
export class RelatedUsersResolver implements Resolve<boolean> {
  constructor(
    private store$: Store,
    private loadingAppService: LoadingAppService,
  ) {
  }

  resolve(): Observable<boolean> {
    this.store$.dispatch(getRelatedUsersAction());
    this.loadingAppService.setState(true);
    return this.store$.pipe(
      select(selectGetRelatedUsersLoaded),
      filter(loaded => loaded),
      take(1),
    );
  }
}
