import { Action, createReducer, on } from '@ngrx/store';
import {
  addUserAction, addUserFailureAction,
  addUserSuccessAction, deleteUserAction, deleteUserFailureAction, deleteUserSuccessAction,
  getUsersAction,
  getUsersFailureAction,
  getUsersSuccessAction, updateUserAction, updateUserFailureAction, updateUserSuccessAction
} from './user.actions';
import { UsersState } from './user.types';
import { User } from '../get-related-users/get-related-users.service';

const initialState: UsersState = {
  loading: false,
  loaded: false,
  userResponse: {data: <User[]>[], pagination: {size: 0}},
  error: null,
  updating: false,
  updated: false,
  addedUser: null,
};

const usersReducer = createReducer<UsersState>(
  initialState,
  on(
    getUsersAction,
    state => ({
      ...state,
      loading: true,
      loaded: false,
    })
  ),
  on(
    getUsersSuccessAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      userResponse: action.userResponse,
      error: null,
    })
  ),
  on(
    getUsersFailureAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    })
  ),
  on(
    addUserAction,
    state => ({
      ...state,
      updating: true,
      updated: false,
      addedUser: null,
    })
  ),
  on(
    addUserSuccessAction,
    (state, action) => ({
      ...state,
      updating: false,
      updated: true,
      addedUser: action.user,
    })
  ),
  on(
    addUserFailureAction,
    (state, action) => ({
      ...state,
      updating: false,
      error: action.error,
      addedUser: null,
    })
  ),
  on(
    updateUserAction,
    state => ({
      ...state,
      updating: true,
      updated: false,
    })
  ),
  on(
    updateUserSuccessAction,
    (state, action) => ({
      ...state,
      updating: false,
      updated: true,
    })
  ),
  on(
    updateUserFailureAction,
    (state, action) => ({
      ...state,
      updating: false,
      error: action.error,
    })
  ),
  on(
    deleteUserAction,
    state => ({
      ...state,
      updating: true,
      updated: false,
    })
  ),
  on(
    deleteUserSuccessAction,
    state => ({
      ...state,
      updating: false,
      updated: true,
    })
  ),
  on(
    deleteUserFailureAction,
    state => ({
      ...state,
      updating: false,
    })
  ),
);

export const reducer = (state: UsersState, action: Action) => usersReducer(state, action);
