import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectPlanModalComponent } from './select-plan-modal/select-plan-modal.component';
import { SharedModule } from '../shared/shared.module';
import { CreateSubscriptionModule } from '../payment/create-subscription/create-subscription.module';
import { LetDirective, PushPipe } from '@ngrx/component';
import { UpdateSubscriptionModule } from '../payment/update-subscription/update-subscription.module';
import { DateFnsModule } from 'ngx-date-fns';
import { StartTrialModule } from '../payment/start-trial/start-trial.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SinglePaymentModalComponent } from "./single-payment-modal/single-payment-modal.component";
import {
  CreateBillingPortalSessionModule
} from "../payment/create-billing-portal-session/create-billing-portal-session.module";

@NgModule({
  declarations: [ SelectPlanModalComponent, SinglePaymentModalComponent ],
  imports: [
    CommonModule,
    SharedModule,
    LetDirective,
    PushPipe,
    DateFnsModule,
    StartTrialModule,
    CreateSubscriptionModule,
    UpdateSubscriptionModule,
    CreateBillingPortalSessionModule,
    NgxPermissionsModule.forChild(),
  ],
  exports: [ SelectPlanModalComponent ],
})
export class SelectPlanModule {
}
