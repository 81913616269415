import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/get-accounts.reducers';
import { GetAccountsEffects } from './store/get-accounts.effects';

@NgModule({
  imports: [
    EffectsModule.forFeature([GetAccountsEffects]),
    StoreModule.forFeature('getAccounts', reducer),
  ],
})
export class GetAccountsModule {}
