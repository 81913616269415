import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { getUsersActivityLogStatsAction, getUsersActivityLogStatsSuccessAction, getUsersActivityLogStatsFailureAction } from './get-users-activity-log-stats.actions';
import { GetUsersActivityLogStatsService } from '../get-users-activity-log-stats.service';

@Injectable()
export class GetUsersActivityLogStatsEffects {
  getUsersActivityLogStats$ = createEffect(() => this.actions$.pipe(
    ofType(getUsersActivityLogStatsAction),
    switchMap(action =>
      this.getUsersActivityLogStatsService.get(action.payload).pipe(
        map(data => getUsersActivityLogStatsSuccessAction({ data })),
        catchError(error => of(getUsersActivityLogStatsFailureAction({ error }))),
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private getUsersActivityLogStatsService: GetUsersActivityLogStatsService,
  ) {
  }
}
