import { Action, createReducer, on } from '@ngrx/store';
import { UploadDocumentState } from './upload-document.types';
import { uploadDocumentAction, uploadDocumentSuccessAction, uploadDocumentFailureAction } from './upload-document.actions';

const initialState = {
  uploading: false,
  uploaded: false,
  data: null,
  error: null,
};

const uploadDocumentReducer = createReducer<UploadDocumentState>(
  initialState,
  on(
    uploadDocumentAction,
    state => ({
      ...state,
      uploading: true,
      uploaded: false,
    })
  ),
  on(
    uploadDocumentSuccessAction,
    (state, action) => ({
      ...state,
      uploading: false,
      uploaded: true,
      data: action.data,
      error: null,
    })
  ),
  on(
    uploadDocumentFailureAction,
    (state, action) => ({
      ...state,
      uploading: false,
      uploaded: false,
      error: action.error,
    })
  ),
);

export const reducer = (state: UploadDocumentState, action: Action) => uploadDocumentReducer(state, action);
