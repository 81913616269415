import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/create-user-activity-log.reducers';
import { CreateUserActivityLogEffects } from './store/create-user-activity-log.effects';

@NgModule({
  imports: [
    EffectsModule.forFeature([ CreateUserActivityLogEffects ]),
    StoreModule.forFeature('createUserActivityLog', reducer),
  ],
})
export class CreateUserActivityLogModule {
}
