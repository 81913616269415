import { Injectable } from '@angular/core';
import { Observable, switchMap, takeWhile, tap } from 'rxjs';
import { Firestore } from '@angular/fire/firestore';
import { select, Store } from '@ngrx/store';
import { selectCurrentUserAccountId } from '../../current-user/store/current-user.selectors';
import { filter } from 'rxjs/operators';
import { inputIsNotNullOrUndefined } from '../../data-access/input-is-not-null-or-undefined';
import { UserService } from '../../auth/services/user.service';
import { getAllCompanyUsers$, UserWithInfo, withInfoForUsers$ } from '../../data-access/users';

export interface CompanyUser extends UserWithInfo {}

@Injectable({
  providedIn: 'root',
})
export class GetCompanyUsersService {

  constructor(private firestore: Firestore, private store$: Store, private userService: UserService) {
  }

  get(): Observable<CompanyUser[]> {
    return <Observable<CompanyUser[]>>this.store$.pipe(
      select(selectCurrentUserAccountId),
      filter(inputIsNotNullOrUndefined),
      switchMap(accountId =>
        getAllCompanyUsers$(this.firestore, accountId).pipe(
          switchMap(users => withInfoForUsers$(this.firestore, users)),
        )
      ),
      takeWhile(() => !!this.userService.currentUser),
    );
  }
}
