import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { selectGetAccountsLoaded } from '../../accounts/get-accounts/store/get-accounts.selectors';
import { getAccountsAction } from '../../accounts/get-accounts/store/get-accounts.actions';

@Injectable({ providedIn: 'root' })
export class LocationsResolver implements Resolve<boolean> {
  constructor(
    private store$: Store,
  ) {
  }

  resolve(): Observable<boolean> {
    this.store$.dispatch(getAccountsAction({}));
    return this.store$.pipe(
      select(selectGetAccountsLoaded),
      filter(loaded => loaded),
      take(1),
    );
  }
}
