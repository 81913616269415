import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UploadAttachmentsService } from '../upload-attachments.service';
import { uploadAttachmentsAction, uploadAttachmentsSuccessAction, uploadAttachmentsFailureAction } from './upload-attachments.actions';

@Injectable()
export class UploadAttachmentsEffects {
  uploadAttachments$ = createEffect(() => this.actions$.pipe(
    ofType(uploadAttachmentsAction),
    switchMap(action =>
      this.uploadAttachmentsService.upload(action.payload).pipe(
        map(data => uploadAttachmentsSuccessAction({ data })),
        catchError(error => of(uploadAttachmentsFailureAction({ error }))),
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private uploadAttachmentsService: UploadAttachmentsService,
  ) {
  }
}
