import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AccountCategory, accountCategorySchema, getAccountCategories$ } from "../../../data-access/account-category";
import { Firestore } from "@angular/fire/firestore";
import { select, Store } from "@ngrx/store";
import { selectCurrentUser, selectCurrentUserCompanyId } from "../../../current-user/store/current-user.selectors";
import { inputIsNotNull, inputIsNotUndefined } from "../../../data-access/input-is-not-null-or-undefined";
import { handleSchema } from '../../../models/handle-schema';

@Injectable({
  providedIn: 'root',
})
export class GetAccountCategoriesService {
  constructor(private firestore: Firestore, private store$: Store) {
  }

  get(): Observable<AccountCategory[]> {
    return this.store$.pipe(
      select(selectCurrentUser),
      filter(inputIsNotNull),
      switchMap(currentUser =>
        getAccountCategories$(this.firestore, currentUser.accountId).pipe(
          handleSchema(accountCategorySchema, currentUser),
        )
      ),
    );
  }
}
