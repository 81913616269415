import { Injectable } from '@angular/core';
import { defer, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserTypes } from './user.types';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { SortEvent } from '../data-access/sort';
import { FilterValue } from '../data-access/filters';
import { User } from './get-related-users/get-related-users.service';
import { PhoneNumberValue } from '../shared/phone-number/phone-number.component';

export interface UserPayload {
  id?: string,
  name: string,
  position: string,
  type: UserTypes,
  phone: PhoneNumberValue | null,
  email: string,
  reportToId: string | string[] | null,
  comment: string,
  activated?: boolean,
}

export interface GetUsersPayload {
  search: string,
  filters: FilterValue[] | null,
  size: number,
  page: number,
  sort: SortEvent,
}

@Injectable({
  providedIn: 'root',
})
export class GetUsersService {
  constructor(private functions: Functions) {
  }

  create(payload: UserPayload): Observable<User> {
    return defer(() =>
      httpsCallable<UserPayload, User>(this.functions, 'createCompanyUser')(payload)
    ).pipe(
      map(response => response.data),
    );
  }

  update(payload: UserPayload): Observable<User> {
    return defer(() =>
      httpsCallable<UserPayload, User>(this.functions, 'updateCompanyUser')(payload)
    ).pipe(
      map(response => response.data),
    );
  }

  delete(id: string): Observable<boolean> {
    return defer(() =>
      httpsCallable<{ id: string }, boolean>(this.functions, 'deleteCompanyUser')({ id })
    ).pipe(
      map(response => response.data),
    );
  }
}
