import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  getChildAccountsAction,
  getChildAccountsFailureAction,
  getChildAccountsSuccessAction
} from './get-child-accounts.actions';
import { GetChildAccountsService } from '../get-child-accounts.service';

@Injectable()
export class GetChildAccountsEffects {
  getChildAccounts$ = createEffect(() => this.actions$.pipe(
    ofType(getChildAccountsAction),
    switchMap(action =>
      this.getChildAccountsService.get(action.payload.id).pipe(
        map(data => getChildAccountsSuccessAction({ data })),
        catchError(error => of(getChildAccountsFailureAction({ error }))),
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private getChildAccountsService: GetChildAccountsService,
  ) {
  }
}
