import { createAction, props } from '@ngrx/store';
import { ActionTypes } from './action-types';
import { Purchase } from '../get-purchase.service';

export const getPurchaseAction = createAction(
  ActionTypes.GET_PURCHASE,
);

export const getPurchaseSuccessAction = createAction(
  ActionTypes.GET_PURCHASE_SUCCESS,
  props<{ purchase: Purchase | null }>()
);

export const getPurchaseFailureAction = createAction(
  ActionTypes.GET_PURCHASE_FAILURE,
  props<{ error: any }>()
);
