import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/get-account-categories.reducers';
import { GetAccountCategoriesEffects } from './store/get-account-categories.effects';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([ GetAccountCategoriesEffects ]),
    StoreModule.forFeature('getAccountCategories', reducer),
  ],
})
export class GetAccountCategoriesModule {}
