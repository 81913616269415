import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GetOutlookCalendarService } from '../get-outlook-calendar.service';
import {
  getOutlookCalendarAction,
  getOutlookCalendarSuccessAction,
  getOutlookCalendarFailureAction,
} from './get-outlook-calendar.actions';

@Injectable()
export class GetOutlookCalendarEffects {
  getOutlookCalendar$ = createEffect(() => this.actions$.pipe(
    ofType(getOutlookCalendarAction),
    switchMap(action =>
      this.getOutlookCalendarService.get(action.payload).pipe(
        map(data => getOutlookCalendarSuccessAction({ data })),
        catchError(error => of(getOutlookCalendarFailureAction({ error }))),
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private getOutlookCalendarService: GetOutlookCalendarService,
  ) {
  }
}
