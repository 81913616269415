import { Injectable } from '@angular/core';
import { combineLatest, Observable, takeWhile } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { selectGetConversationsData } from '../get-conversations/store/get-conversations.selectors';
import { inputIsNotNullOrUndefined } from '../../data-access/input-is-not-null-or-undefined';
import { hasNewMassages } from '../../data-access/conversation';
import { selectCurrentUserId } from '../../current-user/store/current-user.selectors';
import { UserService } from '../../auth/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class HasNewMessagesService {
  constructor(private store$: Store, private userService: UserService) {
  }

  get(): Observable<boolean> {
    return combineLatest([
      this.store$.pipe(
        select(selectCurrentUserId),
        filter(inputIsNotNullOrUndefined),
      ),
      this.store$.pipe(
        select(selectGetConversationsData),
        filter(inputIsNotNullOrUndefined),
      )
    ]).pipe(
      map(([userId, conversations]) => hasNewMassages(userId, conversations)),
      takeWhile(() => !!this.userService.currentUser),
    );
  }
}
