import { Injectable } from '@angular/core';
import { combineLatest, EMPTY, Observable, of, takeWhile } from 'rxjs';
import { Firestore } from '@angular/fire/firestore';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { selectCurrentUser } from '../../current-user/store/current-user.selectors';
import { inputIsNotNullOrUndefined } from '../../data-access/input-is-not-null-or-undefined';
import { getCompanyUsers$, getUsersByIds$, UserRelation, UserWithInfo, withInfoForUsers$ } from '../../data-access/users';
import { isArray, uniq } from 'lodash';
import { PersonalInfo } from '../../data-access/personal-info';
import { UserService } from '../../auth/services/user.service';

export interface User extends UserRelation, PersonalInfo {
  isSuperUser: boolean,
  activated: boolean,
  reportTo: UserWithInfo | null,
  updatedBy?: UserWithInfo,
}

@Injectable({
  providedIn: 'root',
})
export class GetRelatedUsersService {

  constructor(private firestore: Firestore, private store$: Store, private userService: UserService) {
  }

  get(): Observable<User[]> {
    return this.store$.pipe(
      select(selectCurrentUser),
      filter(inputIsNotNullOrUndefined),
      switchMap(currentUser => getCompanyUsers$(this.firestore, currentUser)),
      switchMap(users => {
        const userIds = users.map(user => user.id);
        const extraUserIds = uniq(users.map(user =>
          [
            ...(isArray(user.reportToId) ? user.reportToId : [ user.reportToId ]),
            user.updatedById,
          ].filter(inputIsNotNullOrUndefined)
        ).flat().filter(uid => !users.some(u => u.id === uid)));

        return combineLatest([
          of(users),
          ...(extraUserIds.length > 0 ? [ getUsersByIds$(this.firestore, extraUserIds) ] : []),
        ]).pipe(
          map(data => data.flat()),
          switchMap(users =>
            combineLatest([ withInfoForUsers$(this.firestore, users), of(userIds) ]),
          ),
          catchError(err => {
            console.info('Removing user process...', err);
            return EMPTY;
          }),
        );
      }),
      map(([ allUserWithInfo, userIds ]) => {
        return allUserWithInfo.map(userWithInfo => {
          return {
            ...userWithInfo,
            isSuperUser: userWithInfo.id === userWithInfo.accountId,
            activated: !userWithInfo.disabled,
            reportTo: allUserWithInfo.find(u => {
              const reportToId = userWithInfo.reportToId;
              if (isArray(reportToId)) return reportToId.includes(u.id);
              return u.id === reportToId;
            }) || null,
            updatedBy: allUserWithInfo.find(u => u.id === userWithInfo.updatedById),
          };
        }).filter(userWithInfo => userIds.some(uid => uid === userWithInfo.id))
      }),
      takeWhile(() => !!this.userService.currentUser),
    );
  }
}
