import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/get-child-accounts.reducers';
import { GetChildAccountsEffects } from './store/get-child-accounts.effects';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([ GetChildAccountsEffects ]),
    StoreModule.forFeature('getChildAccounts', reducer),
  ]
})
export class GetChildAccountsModule {
}
