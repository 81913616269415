import { Action, createReducer, on } from '@ngrx/store';
import {
  getLocalSettingsAction, getLocalSettingsSuccessAction, getLocalSettingsFailureAction,
} from './get-local-settings.actions';
import { GetLocalSettingsState } from './get-local-settings.types';

const initialState = {
  loading: false,
  loaded: false,
  data: null,
  error: null,
};

const getLocalSettingsReducer = createReducer<GetLocalSettingsState>(
  initialState,
  on(
    getLocalSettingsAction,
    state => ({
      ...state,
      loading: true,
      loaded: false,
    })
  ),
  on(
    getLocalSettingsSuccessAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      data: action.data,
      error: null,
    })
  ),
  on(
    getLocalSettingsFailureAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    })
  ),
);

export const reducer = (state: GetLocalSettingsState, action: Action) => getLocalSettingsReducer(state, action);
