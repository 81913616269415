import { Action, createReducer, on } from '@ngrx/store';
import {
  getPurchaseAction, getPurchaseSuccessAction, getPurchaseFailureAction,
} from './get-purchase.actions';
import { GetPurchaseState } from './get-purchase.types';

const initialState = {
  loading: false,
  loaded: false,
  purchase: null,
  error: null,
};

const getPurchaseReducer = createReducer<GetPurchaseState>(
  initialState,
  on(
    getPurchaseAction,
    state => ({
      ...state,
      loading: true,
      loaded: false,
    })
  ),
  on(
    getPurchaseSuccessAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      purchase: action.purchase,
      error: null,
    })
  ),
  on(
    getPurchaseFailureAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    })
  ),
);

export const reducer = (state: GetPurchaseState, action: Action) => getPurchaseReducer(state, action);
