import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UploadDocumentService } from '../upload-document.service';
import { uploadDocumentAction, uploadDocumentSuccessAction, uploadDocumentFailureAction } from './upload-document.actions';

@Injectable()
export class UploadDocumentEffects {
  uploadDocument$ = createEffect(() => this.actions$.pipe(
    ofType(uploadDocumentAction),
    switchMap(action =>
      this.uploadDocumentService.upload(action.payload).pipe(
        map(data => uploadDocumentSuccessAction({ data })),
        catchError(error => of(uploadDocumentFailureAction({ error }))),
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private uploadDocumentService: UploadDocumentService,
  ) {
  }
}
