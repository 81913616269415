import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/update-account.reducers';
import { UpdateAccountEffects } from './store/update-account.effects';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([UpdateAccountEffects]),
    StoreModule.forFeature('updateAccount', reducer),
  ]
})
export class UpdateAccountModule {}
