import { CurrentUserState } from './current-user.types';
import { Action, createReducer, on } from '@ngrx/store';
import {
  loadCurrentUserAction,
  loadCurrentUserSuccessAction,
  loadCurrentUserFailureAction,
} from './current-user.actions';

const initialState: CurrentUserState = {
  loading: false,
  loaded: false,
  data: null,
  error: null,
};

const currentUserReducer = createReducer(
  initialState,
  on(
    loadCurrentUserAction,
    state => ({
      ...state,
      loading: true,
      loaded: false,
    })
  ),
  on(
    loadCurrentUserSuccessAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      data: action.data,
      error: null,
    })
  ),
  on(
    loadCurrentUserFailureAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    })
  ),
);

export const reducer = (state: CurrentUserState, action: Action) => currentUserReducer(state, action);

