import { Action, createReducer, on } from '@ngrx/store';
import {
  getChildAccountsAction, getChildAccountsFailureAction, getChildAccountsSuccessAction,
} from './get-child-accounts.actions';
import { GetChildAccountsState } from './get-child-accounts.types';

const initialState = {
  loading: false,
  loaded: false,
  data: null,
  error: null,
};

const getChildAccountsReducer = createReducer<GetChildAccountsState>(
  initialState,
  on(
    getChildAccountsAction,
    state => ({
      ...state,
      loading: true,
      loaded: false,
      data: null,
    })
  ),
  on(
    getChildAccountsSuccessAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      data: action.data,
      error: null,
    })
  ),
  on(
    getChildAccountsFailureAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    })
  ),
);

export const reducer = (state: GetChildAccountsState, action: Action) => getChildAccountsReducer(state, action);
