import { OutlookAuthState } from './outlook-auth.types';
import { Action, createReducer, on } from '@ngrx/store';
import {
  outlookGetAuthUserAction,
  outlookGetAuthUserFailureAction,
  outlookGetAuthUserSuccessAction,
  outlookSignInAction,
  outlookSignInFailureAction,
  outlookSignInSuccessAction,
  outlookSignOutAction,
  outlookSignOutFailureAction,
  outlookSignOutSuccessAction,
} from './outlook-auth.actions';

const initialState: OutlookAuthState = {
  loading: false,
  loaded: false,
  authenticationResult: null,
  error: null,
  user: null,
  token: null,
  tokenLoading: false,
  tokenLoaded: false,
  tokenUpdated: false,
  tokenUpdating: false,
};

const authOutlookReducer = createReducer(
  initialState,
  on(
    outlookSignInAction,
    state => ({
      ...state,
      loading: true,
      loaded: false,
      authenticationResult: null,
      error: null,
    })
  ),
  on(
    outlookSignInSuccessAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      authenticationResult: action.authenticationResult,
      error: null,
    })
  ),
  on(
    outlookSignInFailureAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      authenticationResult: null,
      error: action.error,
    })
  ),
  on(
    outlookSignOutAction,
    state => ({
      ...state,
      loading: true,
      loaded: false,
    })
  ),
  on(
    outlookSignOutSuccessAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      authenticationResult: null,
      error: null,
      user: null,
    })
  ),
  on(
    outlookSignOutFailureAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    })
  ),
  on(
    outlookGetAuthUserAction,
    (state, action) => ({
      ...state,
      userLoading: true,
      userLoaded: false,
    }),
  ),
  on(
    outlookGetAuthUserSuccessAction,
    (state, action) => ({
      ...state,
      userLoading: false,
      userLoaded: true,
      user: action.user,
    }),
  ),
  on(
    outlookGetAuthUserFailureAction,
    (state, action) => ({
      ...state,
      userLoading: false,
      userLoaded: false,
      error: action.error,
    }),
  ),
);

export const reducer = (state: OutlookAuthState, action: Action) => authOutlookReducer(state, action);

