import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { LocalSettings } from '../get-local-settings/get-local-settings.service';
import { UserService } from '../../auth/services/user.service';
import { LocalService } from '../../core/services/local.service';
import { merge } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class UpdateLocalSettingsService {

  constructor(private userService: UserService, private localStore: LocalService) {
  }

  update(payload: LocalSettings): Observable<void> {
    const currentUserId = this.userService.currentUser?.uid;

    if (!currentUserId) {
      throw new Error('Unexpected: Missed user ID');
    }

    return new Observable((subscriber) => {
      const data = this.localStore.get(`repmove-${ currentUserId }`);
      const currentData = data ? (JSON.parse(data) as LocalSettings) : {};

      this.localStore.update(`repmove-${ currentUserId }`, JSON.stringify(merge(currentData, payload)));
      subscriber.complete();
    });
  }
}
