import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/update-tag.reducers';
import { UpdateTagEffects } from './store/update-tag.effects';

@NgModule({
  imports: [
    EffectsModule.forFeature([UpdateTagEffects]),
    StoreModule.forFeature('updateTag', reducer),
  ],
})
export class UpdateTagModule {}
