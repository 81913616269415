import { Action, createReducer, on } from '@ngrx/store';
import {
  getTutorialSettingsAction, getTutorialSettingsSuccessAction, getTutorialSettingsFailureAction,
} from './get-tutorial-settings.actions';
import { GetTutorialSettingsState } from './get-tutorial-settings.types';

const initialState = {
  loading: false,
  loaded: false,
  data: null,
  error: null,
};

const getTutorialSettingsReducer = createReducer<GetTutorialSettingsState>(
  initialState,
  on(
    getTutorialSettingsAction,
    state => ({
      ...state,
      loading: true,
      loaded: false,
    })
  ),
  on(
    getTutorialSettingsSuccessAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      data: action.data,
      error: null,
    })
  ),
  on(
    getTutorialSettingsFailureAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    })
  ),
);

export const reducer = (state: GetTutorialSettingsState, action: Action) => getTutorialSettingsReducer(state, action);
