import { Action, createReducer, on } from '@ngrx/store';
import {
  hasNewMessagesAction, hasNewMessagesActionSuccessAction, hasNewMessagesFailureAction
} from './has-new-messages.actions';
import { HasNewMessagesState } from './has-new-messages.types';

const initialState = {
  loading: false,
  loaded: false,
  data: null,
  error: null,
};

const hasNewMessagesReducer = createReducer<HasNewMessagesState>(
  initialState,
  on(
    hasNewMessagesAction,
    state => ({
      ...state,
      loading: true,
      loaded: false,
    })
  ),
  on(
    hasNewMessagesActionSuccessAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      data: action.data,
      error: null,
    })
  ),
  on(
    hasNewMessagesFailureAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    })
  ),
);

export const reducer = (state: HasNewMessagesState, action: Action) => hasNewMessagesReducer(state, action);
