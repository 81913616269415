import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/get-account.reducers';
import { GetAccountEffects } from './store/get-account.effects';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([GetAccountEffects]),
    StoreModule.forFeature('getAccount', reducer),
  ]
})
export class GetAccountModule {}
