import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { GetTutorialSettingsEffects } from './store/get-tutorial-settings.effects';
import { reducer } from './store/get-tutorial-settings.reducers';
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([ GetTutorialSettingsEffects ]),
    StoreModule.forFeature('getTutorialSettings', reducer),
  ]
})
export class GetTutorialSettingsModule {}
