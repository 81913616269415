import { createAction, props } from '@ngrx/store';
import { ActionTypes } from './action-types';
import { User } from '../get-related-users.service';

export const getRelatedUsersAction = createAction(
  ActionTypes.GET_RELATES_USERS
);

export const getRelatedUsersSuccessAction = createAction(
  ActionTypes.GET_RELATES_USERS_SUCCESS,
  props<{ users: User[] }>()
);

export const getRelatedUsersFailureAction = createAction(
  ActionTypes.GET_RELATES_USERS_FAILURE,
  props<{ error: any }>()
);
