import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/get-tutorial-progress.reducers';
import { GetTutorialProgressEffects } from './store/get-tutorial-progress.effects';

@NgModule({
  imports: [
    EffectsModule.forFeature([GetTutorialProgressEffects]),
    StoreModule.forFeature('getTutorialProgress', reducer),
  ],
})
export class GetTutorialProgressModule {}
