import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { getTutorialProgressAction, getTutorialProgressFailureAction, getTutorialProgressSuccessAction } from './get-tutorial-progress.actions';
import { GetTutorialProgressService } from '../get-tutorial-progress.service';

@Injectable()
export class GetTutorialProgressEffects {
  getTutorialProgress$ = createEffect(() => this.actions$.pipe(
    ofType(getTutorialProgressAction),
    switchMap(action =>
      this.getTutorialProgressService.get().pipe(
        map(tutorialProgress => getTutorialProgressSuccessAction({ tutorialProgress })),
        catchError(error => of(getTutorialProgressFailureAction({ error }))),
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private getTutorialProgressService: GetTutorialProgressService,
  ) {
  }
}
