import { Injectable } from '@angular/core';
import { defer, Observable } from 'rxjs';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { map } from 'rxjs/operators';
import { AccountScope, Contact } from '../../core/account.types';
import { Account } from "../get-accounts/get-accounts.service";
import { AccountCategory } from '../../data-access/account-category';
import { PhoneNumberValue } from '../../shared/phone-number/phone-number.component';

export interface UpdateAccountPayload {
  id: string,
  name: string,
  accountGoal: string,
  address: string,
  accountCategory: AccountCategory | null,
  phone: PhoneNumberValue | null,
  extension: string,
  scope: AccountScope,
  userIds: string[],
  website: string,
  contacts?: Contact[],
  tags: string[],
  source: string,
  reference: string,
}

@Injectable({
  providedIn: 'root',
})
export class updateAccountService {
  constructor(private functions: Functions) {
  }

  update(payload: UpdateAccountPayload): Observable<Account> {
    return defer(() =>
      httpsCallable<UpdateAccountPayload, Account>(this.functions, 'updateAccount')(payload)
    ).pipe(
      map(response => response.data),
    );
  }
}
