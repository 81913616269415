import { Action, createReducer, on } from '@ngrx/store';
import { UpdateTutorialProgressState } from './update-tutorial-progress.types';
import { updateTutorialProgressAction, updateTutorialProgressSuccessAction, updateTutorialProgressFailureAction } from './update-tutorial-progress.actions';

const initialState = {
  updating: false,
  updated: false,
  data: null,
  error: null,
};

const updateTutorialProgressReducer = createReducer<UpdateTutorialProgressState>(
  initialState,
  on(
    updateTutorialProgressAction,
    state => ({
      ...state,
      updating: true,
      updated: false,
    })
  ),
  on(
    updateTutorialProgressSuccessAction,
    (state, action) => ({
      ...state,
      updating: false,
      updated: true,
      data: action.data,
      error: null,
    })
  ),
  on(
    updateTutorialProgressFailureAction,
    (state, action) => ({
      ...state,
      updating: false,
      updated: false,
      error: action.error,
    })
  ),
);

export const reducer = (state: UpdateTutorialProgressState, action: Action) => updateTutorialProgressReducer(state, action);
