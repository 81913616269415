import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  hasNewMessagesAction,
  hasNewMessagesActionSuccessAction,
  hasNewMessagesFailureAction,
} from './has-new-messages.actions';
import { HasNewMessagesService } from '../has-new-messages.service';

@Injectable()
export class HasNewMessagesEffects {
  hasNewMessages$ = createEffect(() => this.actions$.pipe(
    ofType(hasNewMessagesAction),
    switchMap(action =>
      this.hasNewMessagesService.get().pipe(
        map(hasNewMessages => hasNewMessagesActionSuccessAction({ data: { hasNewMessages } })),
        catchError(error => of(hasNewMessagesFailureAction({ error }))),
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private hasNewMessagesService: HasNewMessagesService,
  ) {
  }
}
