import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  outlookSignInAction,
  outlookSignInSuccessAction,
  outlookSignInFailureAction,
  outlookSignOutAction,
  outlookSignOutSuccessAction,
  outlookGetAuthUserAction,
  outlookGetAuthUserSuccessAction,
  outlookGetAuthUserFailureAction,
} from './outlook-auth.actions';
import { exhaustMap, of } from 'rxjs';
import { OutlookAuthService } from '../outlook-auth.service';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class OutlookAuthEffects {
  outlookSignIn$ = createEffect(() => this.actions$.pipe(
    ofType(outlookSignInAction),
    exhaustMap(action =>
      this.outlookAuthService.signIn(action.payload).pipe(
        map(authenticationResult => outlookSignInSuccessAction({ authenticationResult })),
        catchError(error => of(outlookSignInFailureAction({ error }))),
      )
    )
  ));

  outlookSignOut$ = createEffect(() => this.actions$.pipe(
    ofType(outlookSignOutAction),
    exhaustMap(action =>
      this.outlookAuthService.signOut(action.payload?.integration).pipe(
        map(() => outlookSignOutSuccessAction()),
        catchError(error => of(outlookSignInFailureAction({ error }))),
      )
    )
  ));

  outlookGetAuthUser$ = createEffect(() => this.actions$.pipe(
    ofType(outlookGetAuthUserAction),
    exhaustMap(action =>
      this.outlookAuthService.getUser().pipe(
        map(user => outlookGetAuthUserSuccessAction({ user })),
        catchError(error => of(outlookGetAuthUserFailureAction({ error }))),
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private outlookAuthService: OutlookAuthService,
  ) {
  }
}
