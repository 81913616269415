import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { UpdateSubscriptionEffects } from './store/update-subscription.effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/update-subscription.reducers';

@NgModule({
  imports: [
    EffectsModule.forFeature([UpdateSubscriptionEffects]),
    StoreModule.forFeature('updateSubscription', reducer),
  ],
})
export class UpdateSubscriptionModule {}
