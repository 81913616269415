import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { getPurchaseAction } from '../../purchase/get-purchase/store/get-purchase.actions';
import { selectGetPurchaseLoaded } from '../../purchase/get-purchase/store/get-purchase.selectors';

@Injectable({ providedIn: 'root' })
export class PurchaseResolver {
  constructor(
    private store$: Store,
  ) {
  }

  resolve(): Observable<boolean> {
    this.store$.dispatch(getPurchaseAction());
    return this.store$.pipe(select(selectGetPurchaseLoaded), filter(loaded => loaded), take(1));
  }
}
