import { Action, createReducer, on } from '@ngrx/store';
import {
  getCompanyUsersAction, getCompanyUsersSuccessAction, getCompanyUsersFailureAction,
} from './get-company-users.actions';
import { GetCompanyUsersState } from './get-company-users.types';

const initialState = {
  loading: false,
  loaded: false,
  data: null,
  error: null,
};

const getCompanyUsersReducer = createReducer<GetCompanyUsersState>(
  initialState,
  on(
    getCompanyUsersAction,
    state => ({
      ...state,
      loading: true,
      loaded: false,
    })
  ),
  on(
    getCompanyUsersSuccessAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      data: action.data,
      error: null,
    })
  ),
  on(
    getCompanyUsersFailureAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    })
  ),
);

export const reducer = (state: GetCompanyUsersState, action: Action) => getCompanyUsersReducer(state, action);
