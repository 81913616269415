import { Action, createReducer, on } from '@ngrx/store';
import {
  createUserActivityLogAction, createUserActivityLogSuccessAction, createUserActivityLogFailureAction,
} from './create-user-activity-log.actions';
import { CreateUserActivityLogState } from './create-user-activity-log.types';

const initialState = {
  loading: false,
  loaded: false,
  data: null,
  error: null,
};

const createUserActivityLogReducer = createReducer<CreateUserActivityLogState>(
  initialState,
  on(
    createUserActivityLogAction,
    state => ({
      ...state,
      loading: true,
      loaded: false,
    })
  ),
  on(
    createUserActivityLogSuccessAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      data: action.data,
      error: null,
    })
  ),
  on(
    createUserActivityLogFailureAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      data: null,
      error: action.error,
    })
  ),
);

export const reducer = (state: CreateUserActivityLogState, action: Action) => createUserActivityLogReducer(state, action);
