import { Action, createReducer, on } from '@ngrx/store';
import {
  getAccountCategoriesAction,
  getAccountCategoriesFailureAction,
  getAccountCategoriesSuccessAction
} from './get-account-categories.actions';
import { AccountCategoriesState } from './get-account-categories.types';

const initialState = {
  loading: false,
  loaded: false,
  accountCategories: [],
  error: null,
};

const accountCategoriesReducer = createReducer<AccountCategoriesState>(
  initialState,
  on(
    getAccountCategoriesAction,
    state => ({
      ...state,
      loading: true,
      loaded: false,
    })
  ),
  on(
    getAccountCategoriesSuccessAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      accountCategories: action.accountCategories,
      error: null,
    })
  ),
  on(
    getAccountCategoriesFailureAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    })
  ),
);

export const reducer = (state: AccountCategoriesState, action: Action) => accountCategoriesReducer(state, action);
