import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Account, GetAccountsService } from '../get-accounts.service';
import {
  getAccountsAction,
  getAccountsDistinctSuccessAction,
  getAccountsFailureAction,
  getAccountsSuccessAction
} from './get-accounts.actions';
import { chunk, keyBy } from "lodash";

const convertToDistinct = async (locations: Account[]) => {
  const batchOfAccounts = chunk(locations, 500);
  let dictionary = {};
  return new Promise<{ [name: string]: Account }>((resolve) => {
    batchOfAccounts.forEach((list, index) => {
      setTimeout(() => {
        dictionary = { ...dictionary, ...keyBy(list, 'id') };
        if (batchOfAccounts.length - 1 === index) {
          resolve(dictionary);
        }
      }, 0);
    });
  });
};

@Injectable()
export class GetAccountsEffects {
  getAccounts$ = createEffect(() => this.actions$.pipe(
    ofType(getAccountsAction),
    switchMap(action =>
      this.getAccountsService.get(action.userId).pipe(
        map(locations => getAccountsSuccessAction({ locations })),
        catchError(error => of(getAccountsFailureAction({ error }))),
      )
    )
  ));

  getAccountsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(getAccountsSuccessAction),
    switchMap(state => {
      return convertToDistinct(state.locations);
    }),
    map(locations =>
      getAccountsDistinctSuccessAction({ locationsDistinct: locations })
    ),
  ));

  constructor(
    private actions$: Actions,
    private getAccountsService: GetAccountsService,
  ) {
  }
}
