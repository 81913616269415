import { Action, createReducer, on } from '@ngrx/store';
import {
  getOutlookCalendarAction, getOutlookCalendarSuccessAction, getOutlookCalendarFailureAction,
} from './get-outlook-calendar.actions';
import { GetOutlookCalendarState } from './get-outlook-calendar.types';

const initialState = {
  loading: false,
  loaded: false,
  data: null,
  error: null,
};

const getOutlookCalendarReducer = createReducer<GetOutlookCalendarState>(
  initialState,
  on(
    getOutlookCalendarAction,
    state => ({
      ...state,
      loading: true,
      loaded: false,
    })
  ),
  on(
    getOutlookCalendarSuccessAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      data: action.data,
      error: null,
    })
  ),
  on(
    getOutlookCalendarFailureAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    })
  ),
);

export const reducer = (state: GetOutlookCalendarState, action: Action) => getOutlookCalendarReducer(state, action);
