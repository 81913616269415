import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GetRelatedUsersService } from '../get-related-users.service';
import { getRelatedUsersAction, getRelatedUsersFailureAction, getRelatedUsersSuccessAction } from './get-related-users.actions';

@Injectable()
export class GetRelatedUsersEffects {
  getRelatedUsers$ = createEffect(() => this.actions$.pipe(
    ofType(getRelatedUsersAction),
    switchMap(action =>
      this.getRelatedUsersService.get().pipe(
        map(users => getRelatedUsersSuccessAction({ users })),
        catchError(error => of(getRelatedUsersFailureAction({ error }))),
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private getRelatedUsersService: GetRelatedUsersService,
  ) {
  }
}
