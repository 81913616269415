import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UpdateTagService } from '../update-tag.service';
import { updateTagAction, updateTagFailureAction, updateTagSuccessAction } from './update-tag.actions';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class UpdateTagEffects {
  updateTag$ = createEffect(() => this.actions$.pipe(
    ofType(updateTagAction),
    switchMap(action =>
      this.updateTagService.update(action.payload).pipe(
        map(data => updateTagSuccessAction({ data })),
        catchError(error => of(updateTagFailureAction({ error }))),
      )
    )
  ));

  updateTagSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(updateTagSuccessAction),
    tap(() => this.notifyService.success('The tag successfully updated')),
  ), { dispatch: false });

  updateTagFailure$ = createEffect(() => this.actions$.pipe(
    ofType(updateTagFailureAction),
    tap(() => this.notifyService.error('Failure to update tag')),
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private updateTagService: UpdateTagService,
    private notifyService: ToastrService,
  ) {
  }
}
