import { Action, createReducer, on } from '@ngrx/store';
import {
  getIntegrationsAction, getIntegrationsSuccessAction, getIntegrationsFailureAction,
} from './get-integrations.actions';
import { GetIntegrationsState } from './get-integrations.types';

const initialState = {
  loading: false,
  loaded: false,
  integrations: null,
  error: null,
};

const getIntegrationsReducer = createReducer<GetIntegrationsState>(
  initialState,
  on(
    getIntegrationsAction,
    state => ({
      ...state,
      loading: true,
      loaded: false,
    })
  ),
  on(
    getIntegrationsSuccessAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      integrations: action.integrations,
      error: null,
    })
  ),
  on(
    getIntegrationsFailureAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    })
  ),
);

export const reducer = (state: GetIntegrationsState, action: Action) => getIntegrationsReducer(state, action);
