import { Action, createReducer, on } from '@ngrx/store';
import { StartTrialState } from './start-trial.types';
import { startTrialAction, startTrialSuccessAction, startTrialFailureAction } from './start-trial.actions';

const initialState = {
  loading: false,
  loaded: false,
  data: null,
  error: null,
};

const startTrialReducer = createReducer<StartTrialState>(
  initialState,
  on(
    startTrialAction,
    state => ({
      ...state,
      loading: true,
      loaded: false,
      data: null,
      error: null,
    })
  ),
  on(
    startTrialSuccessAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      data: action.data,
    })
  ),
  on(
    startTrialFailureAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    })
  ),
);

export const reducer = (state: StartTrialState, action: Action) => startTrialReducer(state, action);
