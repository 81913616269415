import { Action, createReducer, on } from '@ngrx/store';
import {
  updateAccountAction,
  updateAccountFailureAction,
  updateAccountSuccessAction
} from './update-account.actions';
import { updateAccountState } from './update-account.types';

const initialState = {
  updating: false,
  updated: false,
  data: null,
  error: null,
};

const updateAccountReducer = createReducer<updateAccountState>(
  initialState,
  on(
    updateAccountAction,
    state => ({
      ...state,
      updating: true,
      updated: false,
    })
  ),
  on(
    updateAccountSuccessAction,
    (state, action) => ({
      ...state,
      updating: false,
      updated: true,
      data: action.data,
      error: null,
    })
  ),
  on(
    updateAccountFailureAction,
    (state, action) => ({
      ...state,
      updating: false,
      updated: false,
      error: action.error,
    })
  ),
);

export const reducer = (state: updateAccountState, action: Action) => updateAccountReducer(state, action);
