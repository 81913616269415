import { forkJoin, Observable, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';
import { ref, Storage, uploadBytes } from '@angular/fire/storage';
import { select, Store } from '@ngrx/store';
import { selectCurrentUserAccountId } from '../../current-user/store/current-user.selectors';
import { filter } from 'rxjs/operators';
import { inputIsNotNullOrUndefined } from '../../data-access/input-is-not-null-or-undefined';
import { UploadResult } from "@firebase/storage";

export interface UploadPayload {
  path: 'notes',
  files: {
    fileName: string,
    file: File,
  }[],
}

@Injectable({
  providedIn: 'root',
})
export class UploadAttachmentsService {
  constructor(private storage: Storage, private store: Store) {
  }

  upload(payload: UploadPayload): Observable<UploadResult[]> {
    return this.store.pipe(select(selectCurrentUserAccountId)).pipe(
      filter(inputIsNotNullOrUndefined),
      switchMap(accountId => {
        const forUpload = payload.files.map(file => ({
          ref: ref(this.storage, `${ payload.path }/${ accountId }/${ file.fileName }`),
          file: file.file,
        }));
        return forkJoin(forUpload.map(data => uploadBytes(data.ref, data.file)));
      }),
    );
  }
}
