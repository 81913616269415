import { defer, from, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { map } from 'rxjs/operators';

export interface StartTrialPayload {
  productId: string,
  quantity: number,
}

@Injectable({
  providedIn: 'root',
})
export class StartTrialService {
  constructor(private functions: Functions) {
  }

  create(payload: StartTrialPayload): Observable<any> {
    return defer(() =>
      httpsCallable<StartTrialPayload, any>(this.functions, 'startTrial')(payload)
    ).pipe(
      map(response => response.data),
    );
  }
}
