import { Injectable } from '@angular/core';
import { combineLatest, Observable, switchMap, takeWhile } from 'rxjs';
import { collection, collectionData, Firestore } from '@angular/fire/firestore';
import { select, Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { selectCurrentUser } from '../../../current-user/store/current-user.selectors';
import { inputIsNotNullOrUndefined } from '../../../data-access/input-is-not-null-or-undefined';
import { UserService } from '../../../auth/services/user.service';
import { UserTypes } from '../../../users/user.types';
import { CurrentUser } from '../../../current-user/get-current-user.service';
import { environment } from '../../../../environments/environment';
import { IntegrationModel, IntegrationType } from '../../../data-access/integration';

export interface IntegrationSettings {
  name: string,
  instructionUrl: string,
  integrationUrl: string,
  image: string,
  enabled: boolean,
}

const INTEGRATIONS_SETTINGS: { [type in IntegrationType]: IntegrationSettings } = environment.integrationSettings;

// integrations/{userId|companyId}/{ scope: 'company' | 'own' }/${id}

export interface IntegrationRoles {
  roles: number[],
}

export interface Integration extends IntegrationModel, IntegrationSettings, IntegrationRoles {
}

const getIntegrationRoles = (currentUser: CurrentUser, integration: IntegrationModel): number[] => {
  if (integration.type === 'zapier') {
    return [ UserTypes.OWNER ];
  }
  return currentUser.type === UserTypes.OWNER ? [ UserTypes.OWNER, UserTypes.LEAD, UserTypes.INSIDE_SALES, UserTypes.USER ] : currentUser.type === UserTypes.LEAD ? [ UserTypes.LEAD, UserTypes.USER ] : [ UserTypes.USER ]
}

@Injectable({
  providedIn: 'root',
})
export class GetIntegrationsService {

  constructor(private firestore: Firestore, private store$: Store, private userService: UserService) {
  }

  get(): Observable<Integration[]> {
    return this.store$.pipe(
      select(selectCurrentUser),
      filter(inputIsNotNullOrUndefined),
      switchMap(currentUser => {
        const integrations$ = [];
        if (currentUser.type === UserTypes.OWNER) {
          integrations$.push(
            collectionData(collection(this.firestore, `integrations/${ currentUser.accountId }/company`), {
              idField: 'id'
            }).pipe(
              takeWhile(() => !!this.userService.currentUser),
            )
          );
        }
        integrations$.push(
          collectionData(collection(this.firestore, `integrations/${ currentUser.id }/own`), {
            idField: 'id'
          }).pipe(
            takeWhile(() => !!this.userService.currentUser),
          )
        );
        return combineLatest(integrations$).pipe(map(integrationGroup => integrationGroup.flat())).pipe(
          map(integrations =>
            (integrations as IntegrationModel[]).map(integration => ({
              ...integration,
              roles: getIntegrationRoles(currentUser, integration),
              ...INTEGRATIONS_SETTINGS[integration.type],
            }))
          )
        );
      }),
      takeWhile(() => !!this.userService.currentUser),
    );
  }
}
