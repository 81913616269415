import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StartTrialService } from '../start-trial.service';
import { startTrialAction, startTrialFailureAction, startTrialSuccessAction } from './start-trial.actions';

@Injectable()
export class StartTrialEffects {
  startTrial$ = createEffect(() => this.actions$.pipe(
    ofType(startTrialAction),
    switchMap(action =>
      this.startTrialService.create(action.payload).pipe(
        map(data => startTrialSuccessAction({ data })),
        catchError(error => of(startTrialFailureAction({ error }))),
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private startTrialService: StartTrialService,
  ) {
  }
}
