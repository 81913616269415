import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GetAccountCategoriesService } from '../get-account-categories.service';
import {
  getAccountCategoriesAction,
  getAccountCategoriesFailureAction,
  getAccountCategoriesSuccessAction
} from './get-account-categories.actions';

@Injectable()
export class GetAccountCategoriesEffects {
  getAccountCategories$ = createEffect(() => this.actions$.pipe(
    ofType(getAccountCategoriesAction),
    switchMap(action =>
      this.getAccountCategoriesService.get().pipe(
        map(accountCategories => getAccountCategoriesSuccessAction({ accountCategories })),
        catchError(error => of(getAccountCategoriesFailureAction({ error }))),
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private getAccountCategoriesService: GetAccountCategoriesService,
  ) {
  }
}
