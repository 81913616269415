import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/get-user-activity-logs.reducers';
import { GetUserActivityLogsEffects } from './store/get-user-activity-logs.effects';

@NgModule({
  imports: [
    EffectsModule.forFeature([ GetUserActivityLogsEffects ]),
    StoreModule.forFeature('getUserActivityLogs', reducer),
  ],
})
export class GetUserActivityLogsModule {
}
