import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RemoveReportFiltersService } from '../remove-report-filters.service';
import { ToastrService } from 'ngx-toastr';
import {
  removeReportFiltersAction,
  removeReportFiltersFailureAction,
  removeReportFiltersSuccessAction,
} from './remove-report-filters.actions';

@Injectable()
export class RemoveReportFiltersEffects {
  removeReportFilter$ = createEffect(() => this.actions$.pipe(
    ofType(removeReportFiltersAction),
    switchMap(action =>
      this.removeReportFiltersService.remove(action.payload).pipe(
        map(data => removeReportFiltersSuccessAction({data})),
        catchError(error => of(removeReportFiltersFailureAction({error}))),
      )
    )
  ));

  removeReportFiltersSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(removeReportFiltersSuccessAction),
    tap((action) =>
      this.notifyService.success('The report filter successful removed')
    ),
  ), {dispatch: false});

  removeReportFiltersFailure$ = createEffect(() => this.actions$.pipe(
    ofType(removeReportFiltersFailureAction),
    tap((action) =>
      this.notifyService.error('Failed to remove report filters')
    ),
  ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private removeReportFiltersService: RemoveReportFiltersService,
    private notifyService: ToastrService,
  ) {
  }
}
