import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/has-new-messages.reducers';
import { HasNewMessagesEffects } from './store/has-new-messages.effects';

@NgModule({
  imports: [
    EffectsModule.forFeature([ HasNewMessagesEffects ]),
    StoreModule.forFeature('hasNewMessages', reducer),
  ],
})
export class HasNewMessagesModule {}
