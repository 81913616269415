import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UpdateLocalSettingsService } from '../update-local-settings.service';
import { ToastrService } from 'ngx-toastr';
import {
  updateLocalSettingsAction,
  updateLocalSettingsFailureAction,
  updateLocalSettingsSuccessAction,
} from './update-local-settings.actions';

@Injectable()
export class UpdateLocalSettingsEffects {
  updateLocalSettings$ = createEffect(() => this.actions$.pipe(
    ofType(updateLocalSettingsAction),
    switchMap(action =>
      this.updateLocalSettingsService.update(action.payload).pipe(
        map(data => updateLocalSettingsSuccessAction({data})),
        catchError(error => of(updateLocalSettingsFailureAction({error}))),
      )
    )
  ));

  updateLocalSettingsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(updateLocalSettingsSuccessAction),
    tap((action) =>
      this.notifyService.success('The local settings successful changed')
    ),
  ), {dispatch: false});

  updateLocalSettingsFailure$ = createEffect(() => this.actions$.pipe(
    ofType(updateLocalSettingsFailureAction),
    tap((action) =>
      this.notifyService.error('Failed to save local settings')
    ),
  ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private updateLocalSettingsService: UpdateLocalSettingsService,
    private notifyService: ToastrService,
  ) {
  }
}
