import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { GetOutlookCalendarEffects } from './store/get-outlook-calendar.effects';
import { reducer } from './store/get-outlook-calendar.reducers';
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([ GetOutlookCalendarEffects ]),
    StoreModule.forFeature('getOutlookCalendar', reducer),
  ]
})
export class GetOutlookCalendarModule {}
