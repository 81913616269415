<ng-container *ngIf="!!(isLoading$ | async); else loaded">
  <div fxLayout="column" fxLayoutAlign="center center" appFullHeightContent>
    <app-loading></app-loading>
  </div>
</ng-container>

<ng-template #loaded>
  @if (account$ | async; as account) {
    <div fxLayout="column" fxLayoutGap="1.875rem" *ngIf="allowToViewAccount$ | async as allowToViewAccount; else notFound">
      <ul ngbNav [activeId]="route.fragment | async" class="rmv-tabs">
        <li class="rmv-tabs__item" ngbNavItem="details" routerLinkActive="--active">
          <a class="rmv-tabs__link" ngbNavLink [routerLink]="['details']"
             tourAnchor="account.details.detailsTab">Details</a>
        </li>
        <li class="rmv-tabs__item" ngbNavItem="contacts" routerLinkActive="--active">
          <a class="rmv-tabs__link" ngbNavLink [routerLink]="['contacts']"
             tourAnchor="account.details.contactsTab">Contacts</a>
        </li>
        @if (allowToViewAccount.visibility === 'edit') {
          <li class="rmv-tabs__item" ngbNavItem="tasks" routerLinkActive="--active">
            <a class="rmv-tabs__link" ngbNavLink [routerLink]="['tasks']"
               tourAnchor="account.details.tasksTab">Tasks</a>
          </li>
          <li class="rmv-tabs__item" ngbNavItem="daily-routes" routerLinkActive="--active"
              *ngIf="account.location.lat && account.location.lng">
            <a class="rmv-tabs__link" ngbNavLink [routerLink]="['daily-routes']">Daily Route</a>
          </li>
        }
        <li class="rmv-tabs__item" ngbNavItem="account-activity-logs" routerLinkActive="--active">
          <a class="rmv-tabs__link" ngbNavLink [routerLink]="['documents']">Documents</a>
        </li>
        <li class="rmv-tabs__item" ngbNavItem="shared" routerLinkActive="--active">
          <a class="rmv-tabs__link" ngbNavLink [routerLink]="['shared']">Shared Tags</a>
        </li>
        <li class="rmv-tabs__item" ngbNavItem="account-activity-logs" routerLinkActive="--active">
          <a class="rmv-tabs__link" ngbNavLink [routerLink]="['account-activity-logs']">Activity Logs</a>
        </li>
        @if (account.scope !== 'child') {
          <li class="rmv-tabs__item" ngbNavItem="account-activity-logs" routerLinkActive="--active" *ngrxLet="industry$; let industry">
            <a class="rmv-tabs__link" ngbNavLink [routerLink]="['children']">
              {{ accountCaptions.child | scopeCaption: industry }}s
            </a>
          </li>
        }
      </ul>
      <div>
        <router-outlet></router-outlet>
      </div>
    </div>
  }

  <ng-template #notFound>
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1.5rem" appFullHeightContent>
      <span class="__icon-settings rmv-icon-ic-settings"></span>
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap=".5rem">
        <p class="__not-found-title">Account not found</p>
        <p>Account was deleted, or you do not have access to view the record.</p>
      </div>
    </div>
  </ng-template>
</ng-template>
